import styled from "styled-components";

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;

  cursor: pointer;
  & > img {
    width: 14px;
  }
`;

export const MyDataDefaultContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const SecondaryButton = styled.button`
  margin-bottom: 50px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #2c6598;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #2c6598;
`;
export const MyDataHeader = styled.div`
  & > h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #2c6598;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 33px;
    }
  }
  & > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #656565;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const MyDataForm = styled.form`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 352px;
  transition: all linear 0.4s;
  /*   @media (max-width: 768px) {
    width: 70%;
    margin-bottom: 16px;
  } */
`;

export const MyDataInput = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid #2c6598;
  height: 30px;
  width: 100%;
  margin: 12px 0px;

  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 20%;
    margin-bottom: 4px;
    img {
    }
  }
  & > input {
    margin-bottom: 1px;
    border: none;
    width: 80%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 105.18%;
    letter-spacing: -0.03em;

    color: #2c6598;
    &::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 105.18%;
      /* identical to box height, or 21px */

      letter-spacing: -0.03em;

      color: #2c6598;
    }
  }
`;
