import styled from "styled-components";
import HomeHeaderBannerImgMobile from "../../../../images/header-mobile.png";


export const Container = styled.div`
  width: 1440px;
  @media (max-width: 768px) {
    width: 428px;
  }
`;

export const Header = styled.div`

width: 1440px;
height: 871px;
display: flex;
flex-direction: column;
justify-content: space-around;
background: #9516B9;
@media (max-width: 768px) {
    height: 1057px;
    width: 100%;
  }
  
`

export const HeaderImage = styled.div`
  text-align: center;
  height: 871px;
  width: 1440px;

  background: #9516b9;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 325px;
    width: 100%;
    background-position: top left;
    background: url(${HomeHeaderBannerImgMobile});
  }

  //box-shadow: inset 0 0 0 1000px rgba(83, 147, 203, 0.1);
`;

export const HeaderTitle = styled.div`
 /* position: absolute; */
 width: 356px;
 height: 41px;
 /* left: 542px;
 top: 358px; */

 font-family: Open Sans;
 font-style: normal;
 font-weight: bold;
 font-size: 30px;
 line-height: 41px;
 text-align: center;
letter-spacing: -0.03em;
  color: #fff;
  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const HeaderLogo = styled.div`
  /* position: absolute;
  left: 626px;
  top: 105px; */

  width: 188.94px;
  height: 239px;
 // background: #ffffff;
  border-radius: 32.4307px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  & > img {
    width: 115.51px;
  }
  @media (max-width: 768px) {
    padding: 0px;
    & > img {
      width: 79.56px;
    }
  }
`;
export const HeaderRectangle = styled.div`
/* position: absolute; */
width: 1017px;
height: 4px;
/* left: 211px;
top: 458px; */

background: #FFFFFF;
opacity: 0.4;
border-radius: 4px;
@media (max-width: 768px) {
width: 80%
}
`
export const Benefits = styled.div`
/* position: absolute; */
width: 152px;
height: 38px;
/* left: 644px;
top: 525px; */

font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 38px;
text-align: center;

color: #FFFFFF;
`

export const BenefitsContent = styled.div`
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 23px;
/* or 23px */
/* position: absolute;
top: 591px;
left: 174px; */
text-align: center;
letter-spacing: -0.03em;
display: flex;

justify-content: space-around;
color: #FFFFFF;
margin-bottom: 60px;
@media (max-width: 768px) {
    
    flex-wrap: wrap;
    
    
  }

.info { 
  margin: 0px 35px;
  display: flex;
  flex-direction: column;
 

  & > img {
    margin-bottom: 20px
  }
  
    @media (max-width: 768px) {
    
      margin-bottom: 20px
      
      
    }
  
  
}
`


export const HeaderContent = styled.div`
  background: rgba(83, 147, 203, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 294px;
  & > h1 {
    display: none;
  }
  & > p {
    font-family: Open Sans;
    font-style: normal;

    font-size: 28px;
    line-height: 38px;
    text-align: center;

    color: #2C6598;
    & > span {
      font-weight: bold;
      color: #9516b9;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 557px;
    & > h1 {
      display: inline;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 33.3484px;
      line-height: 115.18%;
      margin-top: 49px;
      margin-bottom: 49px;
      /* or 38px */
      text-align: center;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #9516b9;
    }
    & > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 38px;
      text-align: center;
    }
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px;
  margin-bottom: 50px;
  & > p {
    font-family: Open Sans;
    font-style: normal;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #2C6598;
    font-weight: bold;
    margin-bottom: 50px;
  }
  & > div {
  width: 70%;
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  margin-left: 140px;
  & > .form{
        margin-right: 0px;
      }
 
  }
  
  @media (max-width: 768px) {
    width: 90vw;
    margin-bottom: 50px;
    & > p {
      font-family: Open Sans;
      font-style: normal;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      letter-spacing: -0.03em;
    }
    & > div {
      flex-direction: column;
      width: 100%;
      margin: auto;
      justify-content: center;

      & > .form{
        margin-right: 40px;
      }
    }
    
  }
`;



export const SecondButton = styled.button`
  margin-top: 4px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #9516b9;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #9516b9;
`;
export const MyDataForm = styled.form`
  display: flex;
  padding: 16px 0px;
  
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-right: 30px;
  width: 320px;
  transition: all linear 0.4s;
    @media (max-width: 768px) {
      width: 70%;
    margin-bottom: 16px;
  }
`;
export const PlanContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(83, 147, 203, 0.08);
  padding-top: 90px;

  @media (max-width: 768px) {
    & > div {
      flex-wrap: wrap;
      margin-left: 34px;
    }
  }
`;

export const ContainerPlan = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 15px;
`;

export const CardPlan = styled.div`
  margin: 45px;
  height: 500px;
  margin-top: 45px;
  padding: 30px;
  border: 2px solid #2C6598;
  border-radius: 12px;

  font-family: Open Sans;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;

  width: 350px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  .options {
    height: 300px;
  & > ul {
    list-style-type: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    width: 100%;
    letter-spacing: -0.03em;
    /* identical to box height */
    color: #656565;
    & > li {
      padding-top: 2px;
      & > strong {
        font-weight: 800;
      }
    }
  }
}
`;

export const ContentPrice = styled.div`
  width: 100%;
  height: 200px;
  & > div {
    margin-top: 60px;

    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #656565;
    }
    & > h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 51.1236px;
      line-height: 70px;
      text-align: left;
      letter-spacing: -0.05em;
      color: #2C6598;
      & > span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 13.1461px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #2C6598;
      }
    }
  }
`;

export const PrimaryButton = styled.button`
  margin-top: 4px;
  cursor: pointer;
  border: 2px solid #9516b9;
  border-radius: 12px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;
  text-align: center;
  letter-spacing: -0.03em;
`;

export const SecondaryButton = styled.button`
  margin-top: 4px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #2C6598;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #2C6598;
`;

export const SubSectionTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: left;
  color: #2C6598;
  width: 100%;
`;
