import styled from "styled-components";
import CardBackground from "../../../../images/card_background.png";

export const Box = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
  @media print {
    page-break-after: always !important;
  }
`;
export const DivNoPrint = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media print {
    display: none;
  }
`;

export const CardFront = styled.div`
  position: relative;
  background-image: url(${CardBackground});
  background-position: left bottom;
  background-repeat: no-repeat;
  margin: 10px;
  width: 350px;
  height: 218.75px;
  border: 1px solid gray;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.1);
  @media print {
    background-image: url(${CardBackground}) !important;
    -webkit-print-color-adjust: exact;
  }
`;

export const CardBack = styled.div`
  position: relative;
  margin-top: 6px;
  width: 350px;
  height: 218.75px;
  border: 1px solid gray;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.1);
`;
export const DataInfo = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  //text-align: center;
  flex-direction: column;
`;
export const BoxBackCard = styled.div`
  margin: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.p`
  width: 100%;
  margin-top: 2px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #9516b9;
`;

export const Info = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2c6598;
`;

export const TextBottom = styled.div`
  position: absolute;
  bottom: 30px;
`;

export const FooterBack = styled.div`
  background: #9516b9;
  border-radius: 0 0 8px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-around !important;

  @media print {
    background: #9516b9 !important;
    -webkit-print-color-adjust: exact;
  }

  & > div {
    margin: 4px auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    & > p {
      color: #fff !important;
      font-size: 12px !important;
      font-weight: bold;
      width: 100%;
      text-align: center !important;
    }
    & > span {
      color: #fff !important;
      font-size: 16px !important;
      width: 100%;
      text-align: center !important;
    }
  }

  & > label {
    margin: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #2c6598;
`;

export const Logo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  img {
    width: 80px;
  }
`;
