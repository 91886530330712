import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import MaskedInput from "react-text-mask";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import { maskRegex } from "./maskRegex";
import { MyDataInput } from "./styles";

type InputProps = {
  mask?: any;
  type: InputTypes;
  label: any;
  icon: string;
  placeholder: string;
  value: string;
  onAction: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export const SellerInput = (props: InputProps) => {
  const { lead } = useLeads();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Tooltip
      title={"Esse código foi entregue na sua compra."}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <MyDataInput>
        <div>
          <img src={props.icon} alt="" />
        </div>
        <MaskedInput
          mask={maskRegex[props.label] ? maskRegex[props.label] : false}
          type="text"
          name={props.label}
          id={props.label}
          placeholder={props.placeholder}
          defaultValue={lead[props.label]}
          onChange={props.onAction}
          onFocus={handleOpen}
        />
      </MyDataInput>
    </Tooltip>
  );
};
