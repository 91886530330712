import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FooterColored } from "../../../../components/footer/footerColored/footer";
import { PlanDetail } from "../../../../components/planDetail";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import {
  IClient,
  IPlans,
} from "../../../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import BemMaisLogoColored from "../../../../images/bem_mais_logo_colored.png";
import CashIcon from "../../../../images/cash.svg";
import CicleIcon from "../../../../images/cicle.svg";
import MedalIcon from "../../../../images/medal.svg";
import MessagePlusIcon from "../../../../images/message_plus.svg";
import PlusIcon from "../../../../images/plus.svg";
import ProtraitImgIntro from "../../../../images/protrait_img_intro.png";
import ShieldIcon from "../../../../images/shield.svg";
import {
  CardPlan,
  Container,
  ContainerPlan,
  ContentPrice,
  Header,
  HeaderBottomSection,
  HeaderImage,
  HeaderLogo,
  HeaderTitle,
  HowToWorkContent,
  IntroContent,
  PlanContainer,
  PrimaryButton,
  SecondaryButton,
  SectionTitle,
  ServicesContent,
  SubSectionTitle,
} from "./styles";
import { updateEvent } from "../../../../services/api";

type IHomeProps = {
  client: IClient;
};

export const HomeBemMais = (homeProps: IHomeProps) => {
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { addLead, sendLead, lead, updateLead, leadId } = useLeads();
  const [isOpen, setIsOpen] = useState(false);
  const { handleLocation, updateEvent } = useLeads();
  const { nextPage } = useStylesController();
  const [planSelected, setPlanSelected] = useState<string>("");
  const [productPrice, setProductPrice] = useState<string>("");

  function toggleModal(e: any) {
    setIsOpen(!isOpen);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [planFinalSelected, setPlanFinalSelected] = useState<string>("");

  const addPlanToLead = async (plan: IPlans) => {
    await updateEvent({ leadId: leadId, type: "COMPRAR" });
    MySwal.close();
    console.log(lead);
    const [, value] = plan.price.split(" ");
    await addLead({
      selected_product: plan.name,
      product_price: plan.price,
      product_value: value,
    });
    await updateLead({
      selected_product: plan.name,
      product_price: plan.price,
      product_value: value,
    });

    //await updateEvent("BEMMAIS_ASSINE_JA");

    nextPage(history);
  };

  useEffect(() => {
    setPlanSelected(homeProps.client.plans[0].name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let locationSearch = history.location.search.replace("?", "");
    let queryParams: any = {};
    for (const param of locationSearch.split("&")) {
      let paramSplit = param.split("=");
      queryParams[paramSplit[0]] = paramSplit[1];
    }
    async function requestLocation() {
      await handleLocation(queryParams);
    }

    requestLocation();
  }, []);

  const alertOpen = async (data: any) => {
    await updateEvent({ leadId: leadId, type: "MAIS_DETALHES" });
    MySwal.fire({
      html: (
        <PlanDetail
          isHidden={data.isHidden}
          name={data.name}
          price={data.price}
          addPlanToLead={addPlanToLead}
          plan={data.plan}
        />
      ),
      width: "100%",
      confirmButtonColor: "gray",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: false,
      customClass: {},
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
    });
  };

  return (
    <Container>
      <Header>
        <HeaderImage />
        <HeaderLogo>
          {" "}
          <img src={BemMaisLogoColored} alt="" />
        </HeaderLogo>

        <HeaderTitle>
          A sua saúde <br />
          merece <br />
          muito mais!
        </HeaderTitle>
      </Header>
      <HeaderBottomSection>
        <div>
          <img src={PlusIcon} alt="" />
          <p>Cuidado</p>
        </div>
        <div>
          <img src={PlusIcon} alt="" />
          <p>QUALIDADE DE VIDA</p>
        </div>
        <div>
          <img src={PlusIcon} alt="" />
          <p>FACILIDADE</p>
        </div>
        <div>
          <img src={PlusIcon} alt="" />
          <p>SEGURANÇA</p>
        </div>
        <div>
          <img src={PlusIcon} alt="" />
          <p>BENEFÍCIOS</p>
        </div>
      </HeaderBottomSection>
      <IntroContent>
        <h1>
          Com o
          <span>
            {" "}
            Bem Mais Clube <br /> de Benefícios
          </span>{" "}
          você se <br />
          cuida e a sua saúde e o <br />
          seu bolso agradecem.
        </h1>
        <img src={ProtraitImgIntro} alt="" />
      </IntroContent>
      <ServicesContent>
        <SectionTitle>Conheça os nossos benefícios:</SectionTitle>
        <div>
          <div>
            <img src={MessagePlusIcon} alt="" />
            <h5>
              Atendimento <br />
              médico via <br />
              Telemedicina 24h/7 <br />
              dias por semana
            </h5>
          </div>
          {/* <div>
            <img src={MedalIcon} alt="" />
            <h5>
              Descontos de até 80% <br />
              em farmácias,
              <br /> exames laboratoriais
              <br /> e de imagem
            </h5>
          </div> */}
          {/* <div>
            <img src={CashIcon} alt="" />
            <h5>
              Sorteios mensais
              <br /> no valor de <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div> */}
          <div>
            <img src={ShieldIcon} alt="" />
            <h5>
              Seguro de acidentes
              <br /> pessoais por morte <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div>
          <div>
            <img src={CicleIcon} alt="" />
            <h5>
              Seguro funeral <br />
              para titular da <br />
              apólice
            </h5>
          </div>
        </div>
        <SecondaryButton
          onClick={(e: any) =>
            alertOpen({
              isHidden: true,
              plan: "",
              name: "Plano Família",
              price: "49.90",
            })
          }
        >
          Saiba mais
        </SecondaryButton>
      </ServicesContent>
      <HowToWorkContent>
        <SectionTitle id="saibamais">Como funciona?</SectionTitle>
        <div>
          <div>
            <p>
              <strong>1.</strong> Você escolhe o<br /> plano ideal para
              <br /> você ou para sua
              <br /> família.
            </p>
          </div>
          <div>
            <p>
              <strong>2.</strong> Preenche seus <br />
              dados e efetua o <br />
              pagamento.
            </p>
          </div>
          <div>
            <p>
              <strong>3.</strong> Aproveita
              <br /> todos esses <br />
              benefícios.
            </p>
          </div>
        </div>

        <SecondaryButton>
          <Link to="/#sejabemmais">Seja bem mais!</Link>
        </SecondaryButton>
      </HowToWorkContent>

      <PlanContainer>
        <SectionTitle id="sejabemmais">Qual plano você prefere?</SectionTitle>

        <ContainerPlan>
          {homeProps.client.plans.map((plan: IPlans) => {
            return (
              <CardPlan>
                <SubSectionTitle>{plan.name}</SubSectionTitle>
                <ul>
                  <li>Telemedicina 24 horas</li>
                  {/* <li>Desconto em Exames</li> */}
                  {/* <li>Desconto em Medicamentos</li> */}
                  <li>Orientação Psicológica</li>
                  {/* <li>Desconto Odontológico</li> */}
                  <li>Morte Acidental</li>
                  <li>Funeral Individual</li>
                  {/* <li>Sorteio Mensal</li> */}

                  {plan.name === "Plano Família" && (
                    <li>
                      <strong> Você e mais 3 dependentes</strong>
                    </li>
                  )}
                  {plan.name === "Plano Individual" && (
                    <li>
                      <strong>Sem dependentes</strong>
                    </li>
                  )}
                </ul>

                <ContentPrice>
                  <div>
                    <span>Apenas</span>
                    <h3>
                      {plan.price}
                      <span>/mês</span>
                    </h3>
                  </div>
                  {plan.name === "Plano Família" && (
                    <SecondaryButton
                      onClick={(e: any) =>
                        alertOpen({
                          isHidden: false,
                          plan: plan,
                          name: "Plano Família",
                          price: "49.90",
                        })
                      }
                    >
                      Mais Informações
                    </SecondaryButton>
                  )}
                  {plan.name === "Plano Individual" && (
                    <SecondaryButton
                      onClick={(e: any) =>
                        alertOpen({
                          isHidden: false,
                          plan: plan,
                          name: "Plano Individual",
                          price: "19.90",
                        })
                      }
                    >
                      Mais Informações
                    </SecondaryButton>
                  )}

                  <PrimaryButton
                    onClick={(e: any) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      e.preventDefault;
                      addPlanToLead(plan);
                    }}
                  >
                    Assine já
                  </PrimaryButton>
                </ContentPrice>
              </CardPlan>
            );
          })}
        </ContainerPlan>
      </PlanContainer>
      <FooterColored />
    </Container>
  );
};
