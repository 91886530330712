import styled from "styled-components";

export const FullContainer = styled.div`
  left: 0;
  top: 0;
  background: #9516b9;
  position: fixed;
  width: 100%;
  height: 100%;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #9516b9;
  color: #fff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Box = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  height: 100%;
  margin-top: 20px;
  h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 33.1217px;
    line-height: 45px;
    text-align: center;
  }
  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: px;
    line-height: 48px;
  }
  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28.9815px;
    line-height: 39px;
    text-align: center;
  }
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14.9048px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  a {
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    margin: 10px;
  }
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
