import React from "react";
import { Route, Switch } from "react-router-dom";
import { Card } from "../pages/card/card";
import { CheckoutCallbak } from "../pages/checkout";
import { Home } from "../pages/home/home";
import LinkCode from "../pages/link";
import { MyData } from "../pages/myData/myData";
import { Payment } from "../pages/payment/payment";
import { PendingDefault } from "../pages/pending";
import { SuccessDefault } from "../pages/success";
import { SuccessTelemed } from "../pages/successTelemed";
import Reports from "../pages/reports";
import { Thank } from "../pages/thank/thanks";
import CustomRoute from "./Route";
import { WppButton } from "../components/WppButton";
import {PaymentFail} from "../pages/failed/index"
import { TestTelemedicina } from "../pages/testTelemedicina";

const Routes: React.FC = () => (
  <Switch>
    <div>
      <WppButton />
    <Route path="/pendente/:id" exact component={PendingDefault} />
    <Route path="/sucesso/:id" exact component={SuccessDefault} />
    <Route path="/sucessotelemedicina/:id" exact component={SuccessTelemed} />
    <Route path="/falha/:id" exact component={PaymentFail} />
    <Route path="/comprasegura" exact component={CheckoutCallbak} />
    <Route path="/confirmesuacompra" exact component={Home} />
    <Route path="/telemedicina" exact component={TestTelemedicina} />
    <Route path="/vendedor" exact component={Home} />
    <Route path="/" exact component={Home} />
    <Route path="/meus-dados/:product" exact component={MyData} />
    <CustomRoute path="/meus-dados" exact component={MyData} />
    <Route path="/pagamento" exact component={Payment} />
    <CustomRoute path="/agradecimento" exact component={Thank} />
    <Route path="/cartao" exact component={Card} />
    <Route path="/link" exact component={LinkCode} />
    <Route path="/cartao/:id" exact component={Card} />
    <Route path="/relatorio" exact component={Reports} />
    </div>
  </Switch>
);

export default Routes;
