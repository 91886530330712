import styled, { css } from "styled-components";

export const DefaultMasterPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentMyData = styled.div`
  width: 1018px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DefaultMasterPageNavHeader = styled.header`
  width: 1018px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & > nav {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface PropsNavSelector {
  isSelected: boolean;
  isHidden?: boolean;
}

export const NavSelector = styled.div<PropsNavSelector>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2px;
  ${(props) =>
    props.isHidden &&
    css`
      & > div {
        opacity: 1 !important;
      }
      & > span {
        opacity: 1 !important;
      }
    `}

  ${(props) =>
    props.isSelected &&
    css`
      & > div {
        opacity: 1 !important;
      }
      & > span {
        opacity: 1 !important;
      }
    `}
    
  &:last-child {
    margin-right: 0px !important;
  }
  & > div {
    border-radius: 3px;
    height: 10px;
    width: 100%;
    background-color: #9516b9;
    opacity: 0.25;
  }
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #2c6598;

    opacity: 0.5;
  }
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-left: 2px;
  border: none;
  background-color: transparent;
  width: 80px;
  height: 40px;
  cursor: pointer;
  & > img {
    width: 14px;
  }
  & > span {
    margin-left: 4px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 105.18%;
    /* identical to box height, or 17px */

    text-align: center;
    letter-spacing: -0.03em;

    color: #2c6598;
  }
`;
export const DefaultMasterPageFooter = styled.footer`
  width: 1146px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //position: fixed;
  margin-top: 60px;
  height: 131px;
  background: #9516b9;
  border-radius: 20px 20px 0px 0px;

  & > div:last-child {
    width: 1018px;
    height: 100%;
    //padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 29px;
      line-height: 39px;
      letter-spacing: -0.03em;
      margin-left: 0;
      color: #ffffff;
    }
    & > div {
      justify-content: space-between;
      align-items: flex-end;
      display: flex;

      & > h3 {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 42px;
        line-height: 38px;
        letter-spacing: -0.05em;
        color: #fff;
        & > span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.05em;

          color: #fff;
          margin-bottom: 4px;
        }
      }
    }
    & > button {
      cursor: pointer;
      width: 228px;
      height: 55px;
      border: 2px solid #ffffff;
      border-radius: 12px;
      background-color: transparent;
      margin-right: 0;
      & > span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 105.18%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    height: 100px;

    max-width: 374px;
    & > div:last-child {
      max-width: 334px;
      & > h2 {
        font-size: 22px;
        line-height: 30px;
        max-width: 120px;
      }
      & > button {
        display: none;
      }
      & > div {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-size: 26px;
          line-height: 38px;
        }
        & > button {
          border: none;
          background-color: transparent;
          border-bottom: 2px solid #fff;
          width: 100%;
          & > span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;

            color: #ffffff;
          }
        }
      }
    }
  }
`;
