import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DefaultMasterPage } from "../../components/defaultMasterPage/defaultMasterPage";
import { useLeads } from "../../hooks/leads/leadsHook";
import { PageTypes } from "../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { PaymentDefault } from "./paymentTypes/default/default";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Payment = () => {
  const { currentPage } = useStylesController();
  let query = useQuery();
  const id = query.get("id")
  const { getLead, addLead, lead, updateLead, leadId } = useLeads();

  useEffect(() => {
    if (id) {
      getAndSetLead(id)
    }
  }, [id])
  const getAndSetLead = async (id: any) => {
    await getLead(id)
  }
  if (currentPage.type === PageTypes.BEM_MAIS) {
    return <h1>Payment1</h1>;
  } else {
    return (
      <DefaultMasterPage>
        <PaymentDefault />
      </DefaultMasterPage>
    );
  }
};
