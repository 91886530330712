import { useEffect, useState } from "react";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import { InputSelect } from "./styles";

type InputProps = {
  type: InputTypes;
  label: string;
  icon: string;
  placeholder: string;
  onAction: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
export const ProfessionSelectInput = (props: InputProps) => {
  const { lead, getProfessions } = useLeads();
  const [professions, setProfessions] = useState<Array<any>>([]);

  useEffect(() => {
    getProfessions().then((data: any) => {
      setProfessions(data);
    });
    console.log(professions)
  }, [getProfessions]);

  return (
    <InputSelect>
      <div>
        <img src={props.icon} alt="" />
      </div>

      <select
        placeholder="Profissão"
        name="profession"
        defaultValue={lead.profession}
        onChange={props.onAction}
      >
        <option id="0" key="0" value="profession" selected disabled>
          Profissão
        </option>
        {professions.map((profession: any) => {
          return (
            <option
              id={profession._id}
              value={profession.code}
              key={profession.key}
            >
              {profession.title}
            </option>
          );
        })}
      </select>
    </InputSelect>
  );
};
