import React from "react";
import {
  Redirect,
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
} from "react-router-dom";
import { useLeads } from "../hooks/leads/leadsHook";
import { useStylesController } from "../hooks/stylesController/stylesControllerHook";

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType<any>;
}

const CustomRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { client }: any = useStylesController();
  const { leadId } = useLeads();

  return (
    <ReactDomRoute
      {...rest}
      render={(props) =>
        leadId ? <Component {...props} client={client} /> : <Redirect to="/" />
      }
    />
  );
};

export default CustomRoute;
