import { Container } from "./styles"
import WppIcon from "../../images/wpp-icon.svg"


export const WppButton = () => {

    return (
        <Container>
            <a
                href="https://wa.me/5524999466598"
                target="_blank"
                rel="noreferrer"
                >
                <img src={WppIcon} alt='whatsapp icon' />
            </a>
        </Container>
    )
}