import styled from "styled-components";
import HomeHeaderBannerImgMobile from "../../../../images/header-mobile.png";
import HomeHeaderBannerImg from "../../../../images/header2.png";

export const Container = styled.div`
  width: 1440px;
  @media (max-width: 768px) {
    max-width: 428px;
  }
`;

export const Header = styled.div`
  position: relative;
`;

export const HeaderImage = styled.div`
  text-align: center;
  height: 427px;
  width: 1440px;

  background: url(${HomeHeaderBannerImg});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 325px;
    width: 100%;
    background-position: top left;
    background: url(${HomeHeaderBannerImgMobile});
  }

  //box-shadow: inset 0 0 0 1000px rgba(83, 147, 203, 0.1);
`;

export const HeaderTitle = styled.div`
  position: absolute;
  width: 317px;
  height: 115px;
  left: 211px;
  top: 151px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 46.0499px;
  line-height: 115.18%;
  /* or 53px */
  letter-spacing: -0.04em;
  text-transform: uppercase;

  color: #9516b9;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderLogo = styled.div`
  position: absolute;
  left: 1040px;
  top: 94px;

  width: 188.94px;
  height: 239px;
  background: #ffffff;
  border-radius: 32.4307px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px;
  img {
    width: 115.51px;
  }
  @media (max-width: 768px) {
    width: 128.86px;
    height: 163px;
    border-radius: 22.118px;
    left: 150px;
    top: 244px;

    img {
      width: 79.56px;
    }
  }
`;

export const HeaderContent = styled.div`
  background: rgba(83, 147, 203, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 294px;
  h1 {
    display: none;
  }
  p {
    font-family: Open Sans;
    font-style: normal;

    font-size: 28px;
    line-height: 38px;
    text-align: center;

    color: #5392cb;
    span {
      font-weight: bold;
      color: #9516b9;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 557px;
    h1 {
      display: inline;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 33.3484px;
      line-height: 115.18%;
      margin-top: 49px;
      margin-bottom: 49px;
      /* or 38px */
      text-align: center;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #9516b9;
    }
    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 38px;
      text-align: center;
    }
  }
`;

export const SectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 70px;
  p {
    font-family: Open Sans;
    font-style: normal;

    font-size: 28px;
    line-height: 38px;
    text-align: center;

    color: #5392cb;
    span {
      font-weight: bold;
      color: #9516b9;
    }
  }

  @media (max-width: 768px) {
    p {
      font-family: Open Sans;
      font-style: normal;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      letter-spacing: -0.03em;
      br {
        display: none;
      }
    }
  }
`;

export const ServicesContent = styled.section`
  width: 100%;
  display: flex;
  left: 73px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
  margin-bottom: 100px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 48px 0;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: #5392cb;
      height: 322px;
      width: 251px;
      margin-left: 8px;
      &:last-child {
        margin-right: 8px;
      }

      & > img {
        margin-bottom: 16px;
      }
      & > h5 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 125.68%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
        margin: 0 8px;
        strong {
          font-size: 20px;
        }
      }
      &:nth-child(2) {
        background: #9516b9;
      }
      &:nth-child(4) {
        background: #9516b9;
      }
    }
  }
  @media (max-width: 768px) {
    div {
      flex-wrap: wrap;
      margin-left: 34px;
      div {
        width: 177.32px;
        height: 227.48px;
        margin: 7px;
        &:nth-child(3) {
          background: #9516b9;
        }
        &:nth-child(4) {
          background: #5392cb;
        }
      }
      justify-content: flex-start;
    }
  }
`;
/////

export const SecondaryButton = styled.button`
  margin-top: 4px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #5392cb;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #5392cb;
`;

export const SectionTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  line-height: 50px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #5392cb;
`;
