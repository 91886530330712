import { useStylesController } from "../../../hooks/stylesController/stylesControllerHook";
import { FooterContent } from "./styles";

export const FooterColored = () => {
  const { client } = useStylesController();
  if (client.pageOptions.footerColored) {
    return (
      <FooterContent footer={client.pageOptions.footerColored}>
        <div>
          {client.pageOptions.footerColored && (
            <img src={client.pageOptions.footerColored.img} alt="" />
          )}
          <span>
            Powered by <strong>Bria</strong>
          </span>
        </div>
        <span>
          Powered by <strong>Bria</strong>
        </span>
      </FooterContent>
    );
  }
  return (
    <FooterContent>
      <div>
        <span>
          Powered by <strong>Bria</strong>
        </span>
      </div>
    </FooterContent>
  );
};
