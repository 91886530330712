import styled, { css } from "styled-components";

export const CivilStateSelector = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 12px 0px;
  height: 70px;
`;

export const CivilStateSingleSelector = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #2c6598;
  height: 100%;
  width: 30%;
  border-radius: 12px;
  opacity: 0.5;

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      opacity: 1 !important;
      & > div {
        background: #2c6598;
      }
      & > span {
        font-weight: bold;
      }
    `}

  & > div {
    border: 2px solid #2c6598;
    border-radius: 19px;
    width: 38%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > img {
      width: 14px;
    }
  }
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 105.18%;
    height: 25px;
    text-align: center;

    color: #2c6598;
  }
`;
