import styled, { css } from "styled-components";
import { ISinglePageOption } from "../../../hooks/stylesController/stylesController.interface";

export const FooterContent = styled.div`
  display: flex;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  margin: 20px 0px;
  ${(props: { footer?: Omit<ISinglePageOption, "_id"> }) =>
    props.footer &&
    css`
      background-color: ${props.footer.backgroudColorMain} !important;
      color: ${props.footer.textColorMain} !important;
      & > div {
        border-bottom: 2px solid ${props.footer.textColorMain} !important;

        justify-content: space-between !important;
      }
    `}

  @media (max-width: 768px) {
    & > div {
      width: 90% !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      & > span {
        margin: 8px 0;
      }
    }
  }
  & > div {
    width: 75%;
    height: auto !important;
    display: flex;
    border-bottom: 2px solid #ccc;
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    & > span {
      height: fit-content;
    }
  }
`;
