import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


  a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }
/*   #root {
    height: 100%;
    touch-action: pan-y;
  } */
  * {
    margin: 0 auto ;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }
  textarea:focus, input:focus{
    outline: none;
  }

  body ,html{
   // touch-action: pan-y;
    max-width: 1440px;
    font-family: Open Sans,Poppins, Helvetica, Sans-Serif;
  }
 /*  h1,h2,h3,h4,h5,h6,p,span,button,a{
    padding: 0;
    outline: none;
    text-decoration: none;
  } */

  .logo-client {
    max-width: 100px;
  }
`;

export default GlobalStyle;
