import styled from "styled-components";

export const Col = styled.div`
  margin: 0 auto;
  width: 350px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  h1,
  h2 {
    padding-top: 20px;
  }

  div {
    padding-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;
