import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FooterRender } from "../../../../components/footer/footerRender/footer";
// import { NumberSeriesInput } from "../../../../components/input/numberSerieInput";
import pdfAssistencia from "../../../../documents/assistencias.pdf";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import BuildingGrayIcon from "../../../../images/building_gray.svg";
import CalendaryGrayIcon from "../../../../images/calendary_gray.svg";
import CashIcon from "../../../../images/cash.svg";
import CicleIcon from "../../../../images/cicle.svg";
import HouseGrayIcon from "../../../../images/house_gray.svg";
import LocationGrayIcon from "../../../../images/location_gray.svg";
import MaleGrayIcon from "../../../../images/male_gray.svg";
import FemaleGrayIcon from "../../../../images/female_gray.svg";
import MapGrayIcon from "../../../../images/map-gray.svg";
import MedalIcon from "../../../../images/medal.svg";
import MessageGrayIcon from "../../../../images/message_gray.svg";
import MessagePlusIcon from "../../../../images/message_plus.svg";
import PersonalDocumentGrayIcon from "../../../../images/personal_document_gray.svg";
import PersonGrayIcon from "../../../../images/person_gray.svg";
import ShieldIcon from "../../../../images/shield.svg";
import TelephoneGrayIcon from "../../../../images/telephone_gray.svg";
import { request } from "../../../../services/request";
import Alert from "../../../../utils/sweetAlert";
import { SecondaryButton } from "../../../home/homeTypes/bemMais/styles";
import {
  Box,
  Container,
  LeadInfo,
  NoteStyled,
  PlanInfo,
  Row,
  Subtitle2,
  Title1,
  Title2,
} from "./simpleStyles";
import { useLocation } from "react-router-dom";
import { RowSmall } from "./styles";
import { PaymentMethod } from "../../../../components/paymentMethod";

export const PaymentDefault = () => {
  const history = useHistory();
  const { lead, handleChange, confirm, addLead, updateLead, leadId } =
    useLeads();
  const { currentPage, nextPage } = useStylesController();
  const [numberSeries, setNumberSeries] = useState<string>("");
  const [validateCupom, setValidateCupom] = useState<boolean>(false);
  const [cupomPrice, setCupomPrice] = useState<string>("");

  const checkCupom = async () => {
    if (parseInt(numberSeries) < 5000 || parseInt(numberSeries) > 1000000) {
      return Alert.error("Cupom inválido").then(() => setNumberSeries(""));
    }
    const validCupom = await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `leads/cupom?subAccount=${process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID}&numberSeries=${numberSeries}`,
    });
    if (validCupom.code === 200) {
      setValidateCupom(true);
      return Alert.success(validCupom.message).then(() => {
        addLead({ numberSeries: numberSeries });
        updateLead({
          ...lead,
          numberSeries,
        });
      });
    }
    if (validCupom.code === 403) {
      return Alert.error(validCupom.message).then(() => setNumberSeries(""));
    }
  };

  // useEffect(() => {
  //   console.log(lead);
  //   console.log(leadId);
  //   if (leadId) {
  //     updateLead({
  //       selected_product: "Plano Individual",
  //       product_value: "1990",
  //       product_price: "19.90",
  //     });
  //   }
  // }, [leadId]);
  useEffect(() => {
    console.log(lead);
    console.log(leadId);
    window.scrollTo(0, 0);
    if (lead.selected_product === "Plano Família") setCupomPrice("49,90");
    if (lead.selected_product === "Plano Individual") setCupomPrice("9,90");
  }, []);
  return (
    <Container>
      <Title1>
        {currentPage.section &&
          currentPage.section[0] &&
          currentPage.section[0].textField && (
            <strong>{currentPage.section[0].textField.primaryText}</strong>
          )}
      </Title1>
      <Title1>
        {currentPage.section &&
          currentPage.section[0] &&
          currentPage.section[0].textField &&
          currentPage.section[0].textField.secundaryText}
      </Title1>
      <br />
      <>
        {lead.protocolDate && (
          <>
            <Title2>Protocolo {lead.protocol}</Title2>
            <Subtitle2>
              Gerado em{" "}
              {format(
                parseISO(`${lead.protocolDate}`),
                "dd/MM/yyyy 'às' HH:mm"
              )}
              .
            </Subtitle2>
          </>
        )}
      </>
      <br />
      <Row>
        <Box>
          <Title2>
            {lead.selected_product && lead.selected_product.split("-")[0]}
          </Title2>
          <div>
            <PlanInfo>
              <div>
                <img src={MessagePlusIcon} alt="" />
              </div>
              <span>
                Atendimento médico via Telemedicina 24h/7 dias por semana
              </span>
            </PlanInfo>
            {/* <PlanInfo>
              <div>
                <img src={MedalIcon} alt="" />
              </div>
              <span>
                Descontos de até 80% em farmácias, exames laboratoriais e de
                imagem
              </span>
            </PlanInfo> */}
            {lead.selected_product &&
              lead.selected_product.split("-")[0] === "Plano Individual" && (
                <>
                  <PlanInfo>
                    <div>
                      <img src={MessagePlusIcon} alt="" />
                    </div>
                    <span>Orientação e Apoio Psicológico</span>
                  </PlanInfo>
                  {/* <PlanInfo>
                    <div>
                      <img src={MedalIcon} alt="" />
                    </div>
                    <span>
                      Descontos em consultas e procedimentos odontológicos
                    </span>
                  </PlanInfo> */}
                </>
              )}
            <PlanInfo>
              <div>
                <img src={CashIcon} alt="" />
              </div>
              <span>Sorteios mensais no valor de R$ 10.000,00</span>
            </PlanInfo>
            <PlanInfo>
              <div>
                <img src={ShieldIcon} alt="" />
              </div>
              <span>Seguro de acidentes pessoais por morte R$ 10.000,00</span>
            </PlanInfo>
            <PlanInfo>
              <div>
                <img src={CicleIcon} alt="" />
              </div>
              <span>Seguro funeral para titular da apólice</span>
            </PlanInfo>
          </div>
        </Box>

        <Box>
          <Title2>
            {currentPage.section &&
              currentPage.section[3] &&
              currentPage.section[3].textField &&
              currentPage.section[3].textField.primaryText}
          </Title2>
          <div>
            <LeadInfo>
              <div>
                <img src={PersonGrayIcon} alt="" />
              </div>

              <span> {lead.name && lead.name}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={PersonalDocumentGrayIcon} alt="" />
              </div>

              <span> {lead.cpf && lead.cpf}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={TelephoneGrayIcon} alt="" />
              </div>

              <span> {lead.cellphone && lead.cellphone}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={MessageGrayIcon} alt="" />
              </div>

              <span>{lead.email && lead.email}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={CalendaryGrayIcon} alt="" />
              </div>

              <span>{lead.birthday && lead.birthday}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                {lead.sex && lead.sex === "M" ? (
                  <img src={MaleGrayIcon} alt="" />
                ) : lead.sex && lead.sex === "F" ? (
                  <img src={FemaleGrayIcon} alt="" />
                ) : null}
              </div>

              <span>
                {lead.sex && lead.sex === "M"
                  ? "Masculino"
                  : lead.sex === "F"
                  ? "Feminino"
                  : ""}
              </span>
            </LeadInfo>

            <LeadInfo>
              <div>
                <img src={MapGrayIcon} alt="" />
              </div>

              <span>{lead.address_cep && lead.address_cep}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={HouseGrayIcon} alt="" />
              </div>

              <span> {lead.address_street && lead.address_street}</span>
            </LeadInfo>
            <LeadInfo>
              <div>
                <img src={LocationGrayIcon} alt="" />
              </div>

              <span>{lead.address_number && lead.address_number}</span>
            </LeadInfo>
            {lead.address_complement && (
              <LeadInfo>
                <div>
                  <img src={BuildingGrayIcon} alt="" />
                </div>

                <span>
                  {lead.address_complement && lead.address_complement}
                </span>
              </LeadInfo>
            )}
          </div>
        </Box>
      </Row>
      <PaymentMethod />
      <br />
      <Row>
        <NoteStyled>
          Ao finalizar a compra, declaro que li e concordo com as{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sasiteinstucionalprd01.blob.core.windows.net/siteinstitucional2019/2019/01/15414002721200663.pdf"
          >
            Condições Gerais
          </a>{" "}
          do produto e{" "}
          <a target="_blank" rel="noreferrer" href={pdfAssistencia}>
            Manual de Assistências
          </a>{" "}
          e confirmo que todas informações fornecidas são verdadeiras.
        </NoteStyled>
      </Row>
      <Row>
        <label>
          <Row>
            <input
              type="checkbox"
              onChange={handleChange}
              name="check_roles"
              checked={confirm}
            />
            <NoteStyled>
              Li e concordo com as regras deste seguro, declaração de veracidade
              das informações e conhecimento das Condições Gerais.
            </NoteStyled>
          </Row>
        </label>
      </Row>

      <br />
      {/* <RowSmall>
        <Title2>Insira seu cupom</Title2>
        <NoteStyled>
          Com a validação do cupom, a primeira parcela fica por R$ {cupomPrice}.
        </NoteStyled>
        {!validateCupom && (
          <>
            <NumberSeriesInput
              disabled={validateCupom}
              placeholder="Número da Sorte"
              label="number_series"
              value={numberSeries}
              onAction={(e: any) => setNumberSeries(e.target.value)}
            ></NumberSeriesInput>
            <SecondaryButton disabled={validateCupom} onClick={checkCupom}>
              Usar cupom
            </SecondaryButton>
          </>
        )}
        {validateCupom && (
          <>
            <Title2>Cupom inserido com sucesso.</Title2>
          </>
        )}
      </RowSmall>
      <br /> */}

      <FooterRender />
    </Container>
  );
};
