import { useState } from "react";
import MaskedInput from "react-text-mask";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import CalendaryIconBlue from "../../../images/calendary_blue.svg";
import FemaleIcon from "../../../images/female.svg";
import FemaleDisabledIcon from "../../../images/female_disabled.svg";
import MaleIcon from "../../../images/male.svg";
import MaleDisabledIcon from "../../../images/male_disabled.svg";
import PersonDocumentBlueIcon from "../../../images/personal_document_blue.svg";
import PersonBlueIcon from "../../../images/person_blue.svg";
import TelephoneBlueIcon from "../../../images/telephone_blue.svg";
import { GenreBox, GenreSelector } from "../genreSelectorInput/styles";
import { MyDataInput } from "../textInput/styles";
import { Container } from "./styles";

type InputProps = {
  mask?: any;
  type: InputTypes;
  label: any;
  icon: string;
  placeholder: string;
  value: string;
  quantity?: number;
  onAction: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const Form = (props: any) => {
  const { dependent, addDependent } = useLeads();

  const { selected } = props;
  return (
    <>
      {selected >= 0 && selected < 3 && (
        <>
          <MyDataInput>
            <div>
              <img src={PersonDocumentBlueIcon} alt="" />
            </div>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nome Completo"
              defaultValue={dependent[selected]?.name || ""}
              onChange={(e) => addDependent(selected, "name", e.target.value)}
            />{" "}
          </MyDataInput>
          <MyDataInput key={selected}>
            <div>
              <img src={PersonBlueIcon} alt="" />
            </div>
            <MaskedInput
              mask={[
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
              ]}
              type="text"
              name="cpf"
              placeholder="CPF"
              defaultValue={dependent[selected]?.cpf || ""}
              onChange={(e) => addDependent(selected, "cpf", e.target.value)}
            />
          </MyDataInput>
          <MyDataInput>
            <div>
              <img src={CalendaryIconBlue} alt="" />
            </div>
            <MaskedInput
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              type="text"
              name="birthday"
              id="birthday"
              placeholder="Data de Nascimento"
              defaultValue={dependent[selected]?.birthday || ""}
              onChange={(e) =>
                addDependent(selected, "birthday", e.target.value)
              }
            />
          </MyDataInput>
          <MyDataInput>
            <div>
              <img src={TelephoneBlueIcon} alt="" />
            </div>
            <MaskedInput
              mask={[
                "(",
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              type="text"
              name="cellphone"
              id="cellphone"
              placeholder="Celular"
              defaultValue={dependent[selected]?.cellphone || ""}
              onChange={(e) =>
                addDependent(selected, "cellphone", e.target.value)
              }
            />
          </MyDataInput>
          <GenreSelector>
            <GenreBox
              isSelected={dependent[selected]?.sex === "M" ? true : false}
              onClick={(e) => addDependent(selected, "sex", "M")}
            >
              <div>
                <img
                  src={
                    dependent[selected]?.sex === "M"
                      ? MaleIcon
                      : MaleDisabledIcon
                  }
                  alt=""
                />
              </div>
              <span>Masculino</span>
            </GenreBox>
            <GenreBox
              isSelected={dependent[selected]?.sex === "F" ? true : false}
              onClick={(e) => addDependent(selected, "sex", "F")}
            >
              <div>
                <img
                  src={
                    dependent[selected]?.sex === "F"
                      ? FemaleIcon
                      : FemaleDisabledIcon
                  }
                  alt=""
                />
              </div>
              <span>Feminino</span>
            </GenreBox>
          </GenreSelector>
        </>
      )}
    </>
  );
};

export const DependentInput = (props: InputProps) => {
  const [selected, setSelected] = useState(0);
  const { dependent, addDependent } = useLeads();

  const handleClick = (e: any) => {
    e.preventDefault();

    setSelected(parseInt(e.target.value));
  };

  /*   useEffect(() => {
    console.log(dependent);
  }, [dependent]); */

  return (
    <Container>
      <>
        <button value={0} onClick={handleClick}>
          <h2> Dependente 1</h2>
        </button>
        {selected === 0 && <Form selected={0} />}
      </>
      <>
        <button value={1} onClick={handleClick}>
          <h2> Dependente 2</h2>
        </button>
        {selected === 1 && <Form selected={1} />}
      </>
      <>
        <button value={2} onClick={handleClick}>
          <h2> Dependente 3</h2>
        </button>
        {selected === 2 && <Form selected={2} />}
      </>
    </Container>
  );
};
