import { useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useLeads } from "../../hooks/leads/leadsHook";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { HomeBemMais } from "./homeTypes/bemMais/bemMais";
import { HomeLandingPage } from "./homeTypes/landingPage";
import { HomeSeller } from "./homeTypes/seller";

type IParams = {
  fluxo: string;
};
export const Home = () => {
  const { client, fluxo, handleFluxo } = useStylesController();
  // const params: IParams = useParams();
  const location = useHistory().location.pathname;
  console.log(location);
  const { getLead } = useLeads();
  const newFluxo = location.split("/")[1];
  console.log("fuxo", fluxo);

  useEffect(() => {
    const getFluxoLocalStorage = sessionStorage.getItem(
      process.env.REACT_APP_KEY_FLUXO ?? ""
    );
    console.log("get fluxo", getFluxoLocalStorage);

    if (!getFluxoLocalStorage && !newFluxo) {
      sessionStorage.setItem(
        process.env.REACT_APP_KEY_FLUXO ?? "",
        "sejabemmais"
      );
      handleFluxo("sejabemmais");
    }

    if (getFluxoLocalStorage) {
      handleFluxo(getFluxoLocalStorage);
    }

    if (newFluxo) {
      handleFluxo(newFluxo);
      sessionStorage.setItem(process.env.REACT_APP_KEY_FLUXO ?? "", newFluxo);
    }
  }, []);

  return (
    <>
      {fluxo === "confirmesuacompra" && <HomeLandingPage client={client} />}
      {fluxo === "sejabemmais" && <HomeBemMais client={client} />}
      {fluxo === "vendedor" && <HomeSeller client={client} />}
    </>
  );
};
