import { useStylesController } from "../../../hooks/stylesController/stylesControllerHook";
import { FooterContent } from "./styles";

export const FooterStatic = () => {
  const { client } = useStylesController();
  if (client.pageOptions.footerRenderWhite) {
    return (
      <FooterContent footer={client.pageOptions.footerRenderWhite}>
        <div>
          {client.pageOptions.footerRenderWhite && (
            <img src={client.pageOptions.footerRenderWhite.img} alt="" />
          )}
          <span>
            Powered by <strong>Bria</strong>
          </span>
        </div>
      </FooterContent>
    );
  }
  return (
    <FooterContent>
      <div>
        <span>
          Powered by <strong>Bria</strong>
        </span>
      </div>
    </FooterContent>
  );
};
