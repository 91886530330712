import styled from "styled-components";

export const InputSelect = styled.div`
  width: 100%;
  height: 40px;
  margin: 8px 0px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #2c6598;
  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 20%;
    margin-bottom: 4px;
    img {
    }
  }
  & > select {
    outline: none;
    width: 80%;
    height: 20px;
    border: none;
    background-color: transparent;
    color: #2c6598;
    display: flex;
    align-items: flex-end;

    margin: 12px 0px;
    & > option {
      width: 100%;
      height: 40px;
      border: none;
      background-color: transparent;
      color: #2c6598;
      display: flex;
      align-items: flex-end;
    }
  }
`;
