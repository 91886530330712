import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  & > h1 {
    text-decoration: underline;
  }
`;

export const ThankContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #9516b9;
  color: #fff;
  width: 100%;
  height: 220vh;
  ${(props: { textColorMain?: string; backgroundColorMain?: string }) =>
    props.textColorMain &&
    css`
      color: ${props.textColorMain} !important;
    `}
  ${(props: { backgroundColorMain?: string }) =>
    props.backgroundColorMain &&
    css`
      background: ${props.backgroundColorMain} !important;
    `}
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    text-align: center;
    width: 50%;
    height: 100%;
    & > h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 54px;
    }
    & > div:first-of-type {
      & > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
      }
      & > h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 48px;
      }
    }
    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
    }
    & > p {
      font-family: Open Sans;
      font-style: normal;
      font-size: 25px;
      line-height: 34px;
    }
    & > link {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    & > div {
      width: 50%;
      height: 100%;
      & > h1 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
      }
      & > div:first-of-type {
        & > p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 27px;
        }
        & > h3 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 35px;
        }
      }
      & > span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      & > p {
        font-family: Open Sans;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

export const ContactContainer = styled.div`
  & > p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    max-width: 90vw;
  }
  & > h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    max-width: 90vw;
  }

  & > h2 > a {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 2vw;
    line-height: 54px;
    max-width: 90vw;
  }
  @media (max-width: 768px) {
    & > p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-right: 0px;
    }
    & > h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      word-wrap: break-word;
      line-height: 30px;
      text-align: center;
    }
    & > h2 > a {
      font-size: 2.5vw;
    }
  }
`;
