import axios from "axios";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FooterRenderWhite } from "../../components/footer/footerRenderWhite/footer";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { ContactContainer, ThankContainer, StyledLink } from "./styles";
import { updateEvent } from "../../services/api";

export const SuccessDefault = () => {
  const params: any = useParams();
  const { client } = useStylesController();
  const currentPage = client.pageOptions.pages[5];

  const [lead, setLead] = useState({} as any);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(currentPage);
    console.log(params);
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/leads/${params.id}`)
      .then(async (res: any) => {
        console.log(res.data);
        setLead(res.data);
        await updateEvent({ leadId: params.id, type: "PGTO_SUCESSO" });
      });
  }, []);

  useEffect(() => {
    if (lead._id) {
      axios.put(
        `${process.env.REACT_APP_BASE_API_URL}/leads/serracap/subscribed/${params.id}`
      );
    }
  }, [lead, params]);

  return (
    <ThankContainer
      backgroundColorMain={
        currentPage.backgroudColorMain && currentPage.backgroudColorMain
      }
      textColorMain={currentPage.textColorMain && currentPage.textColorMain}
    >
      {lead?._id && (
        <>
          <div>
            <img src="" alt="" />
            {/* Section 1 */}
            <h1>
              Parabéns, {lead.name && lead.name}! <br />
              {currentPage.section &&
                currentPage.section[0] &&
                currentPage.section[0].textField &&
                currentPage.section[0].textField.primaryText}
            </h1>
            {/* Section 2 */}
            <div>
              <p>
                {currentPage.section &&
                  currentPage.section[1] &&
                  currentPage.section[1].textField &&
                  currentPage.section[1].textField.primaryText}
              </p>
              <h3>{lead.protocol}</h3>
              <p>
                Gerado em{" "}
                {format(
                  parseISO(`${lead.protocolDate}`),
                  "dd/MM/yyyy 'às' HH:mm"
                )}
              </p>
            </div>
            <div>
              <StyledLink to={`/cartao/${params.id}`} target="_blank">
                <h1>Baixe sua carteirinha</h1>
              </StyledLink>
            </div>
            <span>
              {currentPage.section &&
                currentPage.section[1] &&
                currentPage.section[1].textField &&
                currentPage.section[1].textField.secundaryText}
            </span>

            {/* Section 3 */}
            <p>
              <strong>
                {currentPage.section &&
                  currentPage.section[2] &&
                  currentPage.section[2].textField &&
                  currentPage.section[2].textField.primaryText}
              </strong>
              <br />
              {currentPage.section &&
                currentPage.section[2] &&
                currentPage.section[2].textField &&
                currentPage.section[2].textField.secundaryText}
            </p>
            {/* Section 4 */}
            <ContactContainer>
              <p>
                {currentPage.section &&
                  currentPage.section[6] &&
                  currentPage.section[6].textField &&
                  currentPage.section[6].textField.secundaryText}
              </p>
              <h2>
                <a
                  style={{ color: "inherit" }}
                  href={
                    currentPage.section &&
                    currentPage.section[6] &&
                    currentPage.section[6].textField &&
                    currentPage.section[6].textField.href
                  }
                  target="blank"
                >
                  {currentPage.section &&
                    currentPage.section[6] &&
                    currentPage.section[6].textField &&
                    currentPage.section[6].textField.primaryText}
                </a>
              </h2>
            </ContactContainer>
            {/* Section 4 */}
            <ContactContainer>
              <p>
                {currentPage.section &&
                  currentPage.section[3] &&
                  currentPage.section[3].textField &&
                  currentPage.section[3].textField.secundaryText}
              </p>
              <h2>
                <a
                  style={{ color: "inherit" }}
                  href={
                    currentPage.section &&
                    currentPage.section[3] &&
                    currentPage.section[3].textField &&
                    currentPage.section[3].textField.href
                  }
                  target="blank"
                >
                  {currentPage.section &&
                    currentPage.section[3] &&
                    currentPage.section[3].textField &&
                    currentPage.section[3].textField.primaryText}
                </a>
              </h2>
            </ContactContainer>
            {/* Section 5 */}
            <ContactContainer>
              <p>
                {currentPage.section &&
                  currentPage.section[4] &&
                  currentPage.section[4].textField &&
                  currentPage.section[4].textField.secundaryText}
              </p>
              <h2>
                {currentPage.section &&
                  currentPage.section[4] &&
                  currentPage.section[4].textField &&
                  currentPage.section[4].textField.primaryText}
              </h2>
            </ContactContainer>
            {/* Section 6 */}
            <ContactContainer>
              <p>
                {currentPage.section &&
                  currentPage.section[5] &&
                  currentPage.section[5].textField &&
                  currentPage.section[5].textField.secundaryText}
              </p>
              <h2>
                {currentPage.section &&
                  currentPage.section[5] &&
                  currentPage.section[5].textField &&
                  currentPage.section[5].textField.primaryText}
              </h2>
            </ContactContainer>
            {/* Section 7 */}
            <h1>
              {currentPage.section &&
                currentPage.section[6] &&
                currentPage.section[6].textField &&
                currentPage.section[6].textField.primaryText}
            </h1>
          </div>
          <FooterRenderWhite />
        </>
      )}
    </ThankContainer>
  );
};
