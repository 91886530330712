import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FooterRender } from "../../../../components/footer/footerRender/footer";
import { Input } from "../../../../components/input/input";
import NeedHelp from "../../../../components/NeedHelp";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import {
  testDate,
  validateCellphone,
  validateCep,
  validateCpf,
  validateEmail,
  yearsOld,
} from "../../../../utils/utils";
import {
  MyDataDefaultContainer,
  MyDataForm,
  MyDataHeader,
  SecondaryButton,
} from "./styles";
import { request } from "../../../../services/request";

export const MyDataDefault = (props: any) => {
  const MySwal = withReactContent(Swal);
  const { currentPage, nextPage, fluxo } = useStylesController();
  const { lead, addLead, dependent, updateDependents } = useLeads();
  const [sellers, setSellers] = useState<any>({});

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSellers();
  }, []);

  const getSellers = async () => {
    const sellers = await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `sellers/all`,
    });
    if (sellers) {
      setSellers(sellers);
    }
  };

  const isValidSeller = (sellerCode: string) => {
    return sellers.find((seller: any) => seller.sellerCode === sellerCode);
  };
  const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `
      <br/>
      <p>${msg}</p>`,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };

  const validateField = (label: string) => {
    console.log(`: LABEL -> ${label}`);
    console.log(lead.hasOwnProperty(label));
    if (label === "seller_code" && fluxo === "confirmesuacompra") {
      if (!lead.seller_code || !isValidSeller(`${lead.seller_code}`)) {
        return "Código do vendedor inválido.";
      }
    }
    if (label === "name") {
      if (!lead.name || lead.name.length < 10) {
        return "Informe o nome completo";
      }
    }
    if (label === "cpf") {
      if (!lead.cpf || !validateCpf(lead.cpf.replace(/\D/g, ""))) {
        return "CPF inválido.";
      }
    }
    if (label === "dependent") {
      for (const dep of dependent) {
        if (dep.name && dep.name.length < 10) {
          return "Informe o nome completo";
        }
        if (dep.cpf && !validateCpf(dep.cpf.replace(/\D/g, ""))) {
          return "CPF inválido.";
        }
        if (
          dep.cellphone &&
          !validateCellphone(dep.cellphone.replace(/\D/g, ""))
        ) {
          return "Celular inválido!";
        }
      }
    }
    if (label === "civil_state") {
      if (!lead.status_civil) {
        return "Estado civil inválido.";
      }
    }
    if (label === "address_cep") {
      if (!lead.address_cep || !validateCep(lead.address_cep, "")) {
        return "CEP inválido.";
      }
    }
    if (label === "address_street") {
      if (!lead.address_street || lead.address_street === "") {
        return "Verifique o endereço informado";
      }
    }
    if (label === "address_number") {
      if (!lead.address_number || lead.address_number === "") {
        return "Verifique o número de endereço informado";
      }
    }
    if (label === "email") {
      if (!lead.email || !validateEmail(lead.email)) {
        return "Email inválido.";
      }
    }
    if (label === "cellphone") {
      if (
        !lead.cellphone ||
        !validateCellphone(lead.cellphone.replace(/\D/g, ""))
      ) {
        return "Celular inválido!";
      }
    }
    if (label === "birthday") {
      if (yearsOld(lead.birthday) < 18 || yearsOld(lead.birthday) > 80) {
        return "Idade não permitida.";
      }

      if (!lead.birthday || testDate(lead.birthday) < 0) {
        return "Data de nascimento inválida!";
      }
    }

    if (label === "genre") {
      if (!lead.sex || lead.sex === "") {
        return "Gênero não informado.";
      }
    }
  };

  const validateForm = (e: any) => {
    e.preventDefault();

    //console.log(`nextPage: ${JSON.stringify(nPage.section)}`);
    if (currentPage.section) {
      for (const s of currentPage.section) {
        if (s.form) {
          for (const f of s.form) {
            if (f.label) {
              const msg = validateField(f.label);
              if (msg) {
                alertOpen(msg);
                return;
              }
            }

            if (f.label === "dependent") {
              updateDependents();
            }
          }
          nextPage(history);
        }
      }
    }

    nextPage(history);
  };
  /* 
  const replaceDataLead = (label: string, value: any) => {
    const labelsreplace = ["cellphone", "cpf", "address_cep"];
    if (labelsreplace.includes(label)) {
      const _value = value.replace(/\D/g, "");
      addLead({ [label]: _value });
    } else if (label === "birthday") {
      // console.log(value)
      const _birthday = format(
        parse(value, "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );
      // console.log(_birthday)
      addLead({ [label]: _birthday });
    } else {
      addLead({ [label]: value });
    }
  }; */

  return (
    <MyDataDefaultContainer>
      <MyDataHeader>
        <h1>
          <strong>
            {currentPage.section &&
              currentPage.section[0].textField &&
              currentPage.section[0].textField.primaryText}
          </strong>
          <br />
          {currentPage.section &&
            currentPage.section[0].textField &&
            currentPage.section[0].textField.secundaryText}
        </h1>
        <p>
          {currentPage.section &&
            currentPage.section[0].textField &&
            currentPage.section[0].textField.tertiaryText}
          .
        </p>
      </MyDataHeader>
      <MyDataForm>
        {currentPage.section &&
          currentPage.section[1].form &&
          currentPage.section[1].form.map((f, index) => {
            let v: any = lead;
            return (
              <Input
                fluxo={f.fluxo || ""}
                key={f.placeholder}
                type={f.type}
                label={f.label ? f.label : ""}
                icon={f.icon ? f.icon : ""}
                placeholder={f.placeholder ? f.placeholder : ""}
                value={v[`${f.label}`]}
                onAction={(e) => {
                  //replaceDataLead(`${f.label}`, e.target.value);
                  addLead({ [`${f.label}`]: e.target.value });
                }}
              />
            );
          })}
      </MyDataForm>

      <SecondaryButton onClick={validateForm}>
        <span>Prosseguir</span>
      </SecondaryButton>
      <NeedHelp />
      <FooterRender />
    </MyDataDefaultContainer>
  );
};
