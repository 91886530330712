import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export const alertError = (component: any) => {
  MySwal.fire({
    icon: "error",
    html: component,
    confirmButtonColor: "#9516B9",
    confirmButtonText: "Fechar",
    allowOutsideClick: true,
    showCloseButton: true,

    customClass: {},
  });
};
