import { PageTypes } from "../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { ThankDefault } from "./thankTypes/default/default";

export const Thank = () => {
  const { currentPage } = useStylesController();

  if (currentPage.type === PageTypes.BEM_MAIS) {
    return <h1>Agradecimento</h1>;
  } else {
    return <ThankDefault></ThankDefault>;
  }
};
