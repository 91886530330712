import { useEffect } from "react";
import { FooterColored } from "../../../../components/footer/footerColored/footer";
import { Plans } from "../../../../components/plans";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import BemMaisLogoColored from "../../../../images/bem_mais_logo_colored.png";
import CashIcon from "../../../../images/cash.svg";
import CicleIcon from "../../../../images/cicle.svg";
import MedalIcon from "../../../../images/medal.svg";
import MessagePlusIcon from "../../../../images/message_plus.svg";
import ShieldIcon from "../../../../images/shield.svg";
import {
  Container,
  Header,
  HeaderContent,
  HeaderImage,
  HeaderLogo,
  HeaderTitle,
  SectionContent,
  SectionTitle,
  ServicesContent,
} from "./styles";

export const HomeLandingPage = (homeProps: any) => {
  const { handleFluxo } = useStylesController();
  useEffect(() => {
    handleFluxo("confirmesuacompra");
  }, []);

  // useEffect(() => {
  //   console.log(fluxo);
  // }, [fluxo]);

  return (
    <Container>
      <Header>
        <HeaderImage>
          <HeaderTitle>Confirme sua compra</HeaderTitle>
          <HeaderLogo>
            {" "}
            <img src={BemMaisLogoColored} alt="" />
          </HeaderLogo>
        </HeaderImage>
      </Header>
      <HeaderContent>
        <h1>
          Confirme <br />
          sua compra
        </h1>
        <p>
          <strong>Olá</strong>,
          <br /> Você já deu o primeiro passo <br />
          para cuidar da sua saúde com o <br />
          <span>Bem mais Clube de Benefícios!</span>
        </p>
      </HeaderContent>
      <SectionContent>
        <p>
          <strong>
            Preencha seus dados para ativar o seu <br />
          </strong>
          <span>Clube de Benefícios!</span>
        </p>
        <Plans />
      </SectionContent>
      <ServicesContent>
        <SectionTitle>A sua saúde merece mais!</SectionTitle>
        <div>
          <div>
            <img src={MessagePlusIcon} alt="" />
            <h5>
              Atendimento <br />
              médico via <br />
              Telemedicina 24h/7 <br />
              dias por semana
            </h5>
          </div>
          {/* <div>
            <img src={MedalIcon} alt="" />
            <h5>
              Descontos de até 80% <br />
              em farmácias,
              <br /> exames laboratoriais
              <br /> e de imagem
            </h5>
          </div> */}
          <div>
            <img src={CashIcon} alt="" />
            <h5>
              Sorteios mensais
              <br /> no valor de <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div>
          <div>
            <img src={ShieldIcon} alt="" />
            <h5>
              Seguro de acidentes
              <br /> pessoais por morte <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div>
          <div>
            <img src={CicleIcon} alt="" />
            <h5>
              Seguro funeral <br />
              para titular da <br />
              apólice
            </h5>
          </div>
        </div>
      </ServicesContent>
      <FooterColored />
    </Container>
  );
};
