import { Container, InputSelect, MyDataInput } from "./styles";
import salary from "../../images/circle_cash_blue.svg";
import nameIcon from "../../images/person_blue.svg";
import brandIcon from "../../images/personal_document_blue.svg";
import calendar from "../../images/calendary_blue.svg";
import { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { useLeads } from "../../hooks/leads/leadsHook";

export const PaymentMethod = (props: any) => {
  const { handleChangePayment, handleChangePaymentMethod, updateLead } =
    useLeads();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [validity, setValidity] = useState("");

  useEffect(() => {
    updatePaymentData();
  }, [brand, name, number, validity, cvc]);
  const updatePaymentData = async () => {
    // e.preventDefault();
    // if (e.target.id === 'brand') {
    //     await setBrand(e.target.value)
    // }
    // if (e.target.id === 'number') {
    //     await setNumber(e.target.value)
    // }
    // if (e.target.id === 'name') {
    //     await setName(e.target.value)
    // }
    // if (e.target.id === 'validity') {
    //     await setValidity(e.target.value)
    // }
    // if (e.target.id === 'cvc') {
    //     await setCvc(e.target.value)
    // }
    const data = {
      cardBrand: brand,
      cardName: name,
      cardNumber: number,
      cardCvc: cvc,
      cardValidity: validity,
    };
    await handleChangePayment(data);
    console.log(data);
  };

  const updatePaymentMethod = (value: string) => {
    setPaymentMethod(value);
    updateLead({ paymentMethod: value });
    handleChangePaymentMethod(value);
  };
  return (
    <>
      <InputSelect>
        <div>
          <img src={salary} alt="" />
        </div>

        <select
          placeholder="Método de pagamento"
          name="Método de pagamento"
          defaultValue={0}
          onChange={(e) => updatePaymentMethod(e.target.value)}
        >
          <option id="0" key="0" value="payment_method" selected>
            Método de pagamento
          </option>
          <option id="credicard" key="credicard" value="credicard">
            Cartão de crédito
          </option>
          {/* <option id="bankslip" key="bankslip" value="bankslip">
          Boleto bancário
        </option> */}
        </select>
      </InputSelect>
      {paymentMethod === "credicard" && (
        <Container>
          <p>Informe os dados para o pagamento</p>
          <form onSubmit={updatePaymentData}>
            <InputSelect>
              <div>
                <img src={brandIcon} alt="" />
              </div>

              <select
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onChange={(e) => setBrand(e.target.value)}
                name="brand"
                id="brand"
                className="brand"
                defaultValue={0}
              >
                <option key={0} value={0} disabled>
                  Selecione abandeira
                </option>
                <option key={1} value="Visa">
                  Visa
                </option>
                <option key={2} value="Master">
                  Master
                </option>
                <option key={3} value="Amex">
                  Amex
                </option>
                <option key={4} value="Elo">
                  Elo
                </option>
                <option key={5} value="Aura">
                  Aura
                </option>
                <option key={6} value="JCB">
                  JCB
                </option>
                <option key={7} value="Diners">
                  Diners
                </option>
                <option key={8} value="Diners">
                  Discover
                </option>
                <option key={9} value="Diners">
                  Hipercard
                </option>
              </select>
            </InputSelect>
            <MyDataInput>
              <div>
                <img src={brandIcon} alt="" />
              </div>
              <MaskedInput
                onChange={(e) => setNumber(e.target.value)}
                mask={false}
                type="text"
                name="number"
                id="number"
                placeholder="Número do cartão"
              />
            </MyDataInput>
            <MyDataInput>
              <div>
                <img src={nameIcon} alt="" />
              </div>
              <MaskedInput
                onChange={(e) => setName(e.target.value)}
                mask={false}
                type="text"
                name="name"
                id="name"
                placeholder="Nome do cartão"
              />
            </MyDataInput>
            <MyDataInput>
              <div>
                <img src={calendar} alt="" />
              </div>
              <MaskedInput
                onChange={(e) => setValidity(e.target.value)}
                mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                type="text"
                name="validity"
                id="validity"
                placeholder="Data de validade (mm/aaaa)"
              />
            </MyDataInput>
            <MyDataInput>
              <div>
                <img src={brandIcon} alt="" />
              </div>
              <MaskedInput
                onChange={(e) => setCvc(e.target.value)}
                mask={false}
                type="text"
                name="cvc"
                id="cvc"
                placeholder="CVC"
              />
            </MyDataInput>
          </form>
        </Container>
      )}
    </>
  );
};
