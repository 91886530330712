import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FooterStatic } from "../../components/footer/footerStatic/footer";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import starIcon from "../../images/alertcirclewhite.svg";
import { request } from "../../services/request";
import { updateEvent } from "../../services/api";
import { Box, Container, FullContainer } from "./styles";
export const PaymentFail = () => {
  const params: any = useParams();
  const history: any = useHistory();
  const { client } = useStylesController();
  const currentPage = client.pageOptions.pages[5];

  const [lead, setLead] = useState({} as any);

  const getLead = async (id: string) => {
    const data = await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `leads/${params.id}`,
      data: { sql_pgto: true },
    });
    setLead(data);
  };
  useEffect(() => {
    console.log(lead)
  },[lead])
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(currentPage);
    console.log(params);
    getLead(params.id);
  }, []);
  useEffect(() => {
    console.log(lead);
    console.log(history);
  }, [lead]);

  return (
    <FullContainer>
      <Container>
        {lead?._id && (
          <Box>
            <img src={starIcon} alt="" />
            {/* Section 1 */}
            <h1>Ops! Nosso sistema se comportou de forma inesperada :(</h1>
         
          <h2>Tente novamente ou contate nossa central</h2>
            {/* Section 2 */}

            <br />
            <p>Aqui está o seu protocolo:</p>
            <h3>{lead.protocol}</h3>
            <p>
              Gerado em{" "}
              {format(
                parseISO(`${lead.protocolDate}`),
                "dd/MM/yyyy 'às' HH:mm"
              )}
            </p>
            <br />
            <p>Central de Atendimento:</p>
            <h3>0800 942 3040</h3>
            <br />
            <p>
              <strong>
                Bem Mais Clube de Benefícios Você merece muito mais!
              </strong>
            </p>
          </Box>
        )}
        <FooterStatic />
      </Container>
    </FullContainer>
  );
};
