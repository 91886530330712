import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import { InputSelect } from "./styles";

type InputProps = {
  type: InputTypes;
  label: string;
  icon: string;
  placeholder: string;
  onAction: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
export const SalarySelectInput = (props: InputProps) => {
  const { lead } = useLeads();
  return (
    <InputSelect>
      <div>
        <img src={props.icon} alt="" />
      </div>

      <select
        placeholder={props.placeholder}
        name="salary"
        defaultValue={lead.salary}
        onChange={props.onAction}
      >
        <option id="0" key="0" value="salary" selected disabled>
          Média Salarial
        </option>
        <option id="1500" key="1500" value="1500">
          Até R$ 1.500,00
        </option>
        <option id="3000" key="3000" value="3000">
          Até R$ 3.000,00
        </option>
        <option id="5000" key="5000" value="5000">
          Acima de R$ 5.000,00
        </option>
      </select>
    </InputSelect>
  );
};
