import styled from "styled-components";
import HomeHeaderBannerImgMobile from "../../../../images/header-mobile.png";
import HomeHeaderBannerImg from "../../../../images/header.png";

export const Container = styled.div`
  width: 1440px;
  //background: rgba(83, 147, 203, 0.08);
  @media (max-width: 768px) {
    max-width: 428px;
  }
`;

export const Header = styled.div`
  position: relative;
`;

export const HeaderImage = styled.div`
  text-align: center;
  height: 605px;
  background: url(${HomeHeaderBannerImg});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 325px;
    background-position: top left;
    background: url(${HomeHeaderBannerImgMobile});
  }

  //box-shadow: inset 0 0 0 1000px rgba(83, 147, 203, 0.1);
`;

export const HeaderLogo = styled.div`
  position: absolute;
  top: 142px;
  left: 174px;
  height: 321px;
  width: 253.77px;
  background: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 128.86px;
    height: 163px;
    border-radius: 22.118px;
    left: 150px;
    top: 244px;

    img {
      width: 79.56px;
    }
  }
`;

export const HeaderTitle = styled.div`
  position: absolute;
  width: 297px;
  height: 158.65px;
  left: 951px;
  top: 162px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 46.0499px;
  line-height: 115.18%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderBottomSection = styled.div`
  width: 100%;
  height: 338px;
  display: flex;

  background: rgba(83, 147, 203, 0.08);
  div:first-child {
    margin-left: 190px;
  }
  div {
    margin-top: 99px;
    margin-right: 100px;
    text-align: left;

    span {
      width: 32px;
      height: 75px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 55px;
      line-height: 75px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #9516b9;
    }
    p {
      text-transform: uppercase;
      width: 113px;
      height: 31px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 125.68%;
      letter-spacing: -0.03em;
      color: #5392cb;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 566px;
    padding-top: 90px;
    div:first-child {
      margin-left: 0;
    }
    div {
      width: 142.98px;
      height: 87.78px;
      text-align: center;
      margin: 20px;
      span {
        width: 28.96px;
        height: 67.87px;
        font-size: 49.7705px;
        line-height: 68px;
        text-align: center;
      }
      p {
        text-transform: uppercase;
        width: 102.26px;
        height: 28.05px;
        font-size: 22.6229px;
        line-height: 125.68%;
      }
    }
  }
`;

export const IntroContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 90px;
  h1 {
    margin-left: 190px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 37px;
    line-height: 50px;
    text-align: left;
    letter-spacing: -0.03em;
    color: #5392cb;
    span {
      color: rgba(149, 22, 185, 1);
    }
  }
  img {
    margin-left: 110px;
    width: 550.39px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    h1 {
      margin-left: 48px;
      font-size: 28.6127px;
      line-height: 39px;
    }
    div {
      text-align: left;
    }
    img {
      width: 377.9px;
      margin-left: 24px;
    }
  }
`;
////Intro end

export const ServicesContent = styled.section`
  width: 100%;
  display: flex;
  left: 73px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 48px 0;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: #5392cb;
      height: 322px;
      width: 251px;
      margin-left: 8px;
      &:last-child {
        margin-right: 8px;
      }

      & > img {
        margin-bottom: 16px;
      }
      & > h5 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 125.68%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #ffffff;
        margin: 0 8px;
        strong {
          font-size: 20px;
        }
      }
      &:nth-child(2) {
        background: #9516b9;
      }
      &:nth-child(4) {
        background: #9516b9;
      }
    }
  }
  @media (max-width: 768px) {
    div {
      flex-wrap: wrap;
      margin-left: 34px;
      div {
        width: 177.32px;
        height: 227.48px;
        margin: 7px;
        &:nth-child(3) {
          background: #9516b9;
        }
        &:nth-child(4) {
          background: #5392cb;
        }
      }
      justify-content: flex-start;
    }
  }
`;
/////

export const HowToWorkContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 90px;
  & > h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 37px;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: #5392cb;
  }
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 48px 0;

    & > div {
      background: rgba(83, 147, 203, 0.12);
      border-radius: 12px;
      margin: 10px;
      width: 358px;
      height: 246px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        letter-spacing: -0.03em;

        color: #5392cb;
        strong {
          font-weight: 800;
        }
      }
    }
  }

  @media (max-width: 768px) {
    div {
      flex-wrap: wrap;
      margin-left: 34px;
    }
  }
`;

export const PlanContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(83, 147, 203, 0.08);
  padding-top: 90px;
`;

export const ContainerPlan = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 45px;
`;

export const CardPlan = styled.div`
  margin: 45px;

  margin-top: 45px;
  padding: 30px;
  border: 2px solid #5392cb;
  border-radius: 12px;

  font-family: Open Sans;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 350px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  ul {
    list-style-type: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    width: 100%;
    letter-spacing: -0.03em;
    /* identical to box height */
    color: #656565;
    li {
      padding-top: 2px;
      strong {
        font-weight: 800;
      }
    }
  }
`;
export const ContentPrice = styled.div`
  width: 100%;
  div {
    margin-top: 60px;

    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #656565;
    }
    & > h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 51.1236px;
      line-height: 70px;
      text-align: left;
      letter-spacing: -0.05em;
      color: #5392cb;
      & > span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 13.1461px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #5392cb;
      }
    }
  }
`;

export const PrimaryButton = styled.button`
  margin-top: 4px;
  cursor: pointer;
  border: 2px solid #9516b9;
  background: #9516b9;
  border-radius: 12px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #fff;
`;

export const SecondaryButton = styled.button`
  margin-top: 4px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #5392cb;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #5392cb;
`;

export const SectionTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  line-height: 50px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #5392cb;
`;

export const SubSectionTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: left;
  color: #5392cb;
  width: 100%;
`;
