export enum PageTypes {
  DEFAULT = "DEFAULT",
  BEM_MAIS = "BEM_MAIS",
}

export enum JorneyTypes {
  B2C = "B2C",
}

export enum InputTypes {
  SELLER = "SELLER",
  TEXT = "TEXT",
  CIVIL_STATE = "CIVIL_STATE",
  GENRE_SELECTOR = "GENRE_SELECTOR",
  PROFESSION_SELECTOR = "PROFESSION_SELECTOR",
  SALARY = "SALARY",
  DEPENDENT = "DEPENDENT",
}

export enum Pages {
  HOME = "HOME",
  PLANOS = "PLANOS",
  MEUS_DADOS = "MEUS_DADOS",
  PAGAMENTO = "PAGAMENTO",
  DEPENDENTE = "DEPENDENTES",
  AGRADECIMENTO = "AGRADECIMENTO",
}
export enum PaymentOptions {
  PIX = "PIX",
  CREDIT = "CREDIT",
}
export enum PlansTypes {
  SINGLE_PLAN = "SINGLE_PLAN",
  MULTI_PLAN = "MULTI_PLAN",
}

export type IStylesControllerConfigs = {};

// export type IOptions = {
//   name: string;
//   price: string;
// };

export type IServices = {
  title: string;
  type?: string;
  description?: string;
  subDescription?: string;
  subtitle?: string;
  img?: string;
  icon?: string;
  price?: string;
  // options: Array<IOptions>;
};

export type IPlans = {
  name: string;
  type?: PlansTypes;
  description?: string;
  img?: string;
  price: string;
  services: Array<IServices>;
  info?: Array<string>;
};

export type IPaymentProviders = {
  name: string;
  paymentOption: Array<PaymentOptions>;
};

export type ITextFields = {
  primaryText?: string;
  secundaryText?: string;
  tertiaryText?: string;
  quaternaryText?: string;
  textColor1?: string;
  textColor2?: string;
  href?: string;
  mask?: Array<any>;
};

export type IForm = {
  type: InputTypes;
  label?: string;
  icon?: string;
  placeholder?: string;
  mask?: Array<any>;
  quantity?: number;
  fluxo?: string;
};

export type ISection = {
  backgroundColor?: string;
  textColor?: string;
  textField?: ITextFields;
  form?: Array<IForm>;
};

export type ISinglePageOption = {
  _id: string;
  page?: Pages;
  type: PageTypes;
  section?: Array<ISection>;
  backgroudColorMain?: string;
  backIcon?: string;
  textColorMain?: string;
  paymentProvider?: Array<IPaymentProviders>;
  img?: string;
  label?: string;
  conditionalProduct?: string;
};

export type IPageOptions = {
  masterPage?: Omit<ISinglePageOption, "_id">;
  footerColored?: Omit<ISinglePageOption, "_id">;
  footerRender?: Omit<ISinglePageOption, "_id">;
  footerRenderWhite?: Omit<ISinglePageOption, "_id">;
  pages: Array<ISinglePageOption>;
};
export type IClient = {
  plans: Array<IPlans>;
  pageOptions: IPageOptions;
  jorneyType: JorneyTypes;
};
