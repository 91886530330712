import { useStylesController } from "../../../hooks/stylesController/stylesControllerHook";
import { FooterContent } from "./styles";

export const FooterRender = () => {
  const { client } = useStylesController();

  return (
    <>
      {client.pageOptions.footerRender ? (
        <FooterContent footer={client.pageOptions.footerRender}>
          <div>
            {client.pageOptions.footerRender && (
              <img src={client.pageOptions.footerRender.img} alt="" />
            )}
            <span>
              Powered by <strong>Bria</strong>
            </span>
          </div>
          <span>
            Powered by <strong>Bria</strong>
          </span>
        </FooterContent>
      ) : (
        <FooterContent>
          <div>
            <span>
              Powered by <strong>Bria</strong>
            </span>
          </div>
        </FooterContent>
      )}
    </>
  );
};
