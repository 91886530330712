import { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useStylesController } from "../..//hooks/stylesController/stylesControllerHook";
import { PlanDetail } from "../../components/planDetail";
import { useLeads } from "../../hooks/leads/leadsHook";
import { IPlans } from "../../hooks/stylesController/stylesController.interface";
import {
  CardPlan,
  ContainerPlan,
  ContentPrice,
  PrimaryButton,
  SecondaryButton,
  SubSectionTitle,
} from "./styles";

export const Plans = (plansProps: any) => {
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { addLead, sendLead, updateLead } = useLeads();

  const { nextPage, client, fluxo } = useStylesController();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [planFinalSelected, setPlanFinalSelected] = useState<string>("");

  const addPlanToLead = async (plan: IPlans) => {
    MySwal.close();
    const [, value] = plan.price.split(" ");
    addLead({
      selected_product: plan.name,
      product_price: plan.price,
      product_value: value,
    });
    await updateLead({
      selected_product: plan.name,
      product_price: plan.price,
      product_value: value,
    });

    if (fluxo === "vendedor") {
      return history.push("/pagamento");
    }
    return nextPage(history);
  };

  const alertOpen = (data: any) => {
    MySwal.fire({
      html: (
        <PlanDetail
          isHidden={data.isHidden}
          name={data.name}
          price={data.price}
          addPlanToLead={addPlanToLead}
          plan={data.plan}
        />
      ),
      width: "100%",
      confirmButtonColor: "gray",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: false,
      customClass: {},
      showClass: {
        backdrop: "swal2-noanimation", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
    });
  };

  return (
    <ContainerPlan>
      {client.plans.map((plan: IPlans) => {
        return (
          <CardPlan>
            <SubSectionTitle>{plan.name}</SubSectionTitle>
            <ul>
              <li>Telemedicina 24 horas</li>
              <li>Morte Acidental</li>
              <li>Funeral Individual</li>
              <li>Sorteio Mensal</li>
              <li>Orientação Psicológica</li>
              <li>Desconto Odontológico</li>
              {plan.name === "Plano Família" && (
                <li>
                  <strong> Você e mais 3 dependentes</strong>
                </li>
              )}
              {plan.name === "Plano Individual" && (
                <li>
                  <strong>Sem dependentes</strong>
                </li>
              )}
            </ul>

            <ContentPrice>
              <div>
                <span>Apenas</span>
                <h3>
                  {plan.price}
                  <span>/mês</span>
                </h3>
              </div>
              {plan.name === "Plano Família" && (
                <SecondaryButton
                  onClick={(e: any) =>
                    alertOpen({
                      isHidden: false,
                      plan: plan,
                      name: "Plano Família",
                      price: "49.90",
                    })
                  }
                >
                  Mais Informações
                </SecondaryButton>
              )}
              {plan.name === "Plano Individual" && (
                <SecondaryButton
                  onClick={(e: any) =>
                    alertOpen({
                      isHidden: false,
                      plan: plan,
                      name: "Plano Individual",
                      price: "19.90",
                    })
                  }
                >
                  Mais Informações
                </SecondaryButton>
              )}

              <PrimaryButton
                onClick={(e: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  e.preventDefault;
                  addPlanToLead(plan);
                }}
              >
                Assine já
              </PrimaryButton>
            </ContentPrice>
          </CardPlan>
        );
      })}
    </ContainerPlan>
  );
};
