import styled from "styled-components";

export const InputSelect = styled.div`
  width: 300px;
  height: 40px;
  margin: 8px 0px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #2c6598;
  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 20%;
    margin-bottom: 4px;
    img {
    }
  }
  & > select {
    outline: none;
    width: 90%;
    height: 25px;
    border: none;
    background-color: transparent;
    color: #2c6598;
    display: flex;
    align-items: flex-end;

    margin: 12px 0px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 105.18%;
    margin-bottom: 4px;
    letter-spacing: -0.03em;
    color: #2c6598;
    & > option {
      width: 100%;
      height: 40px;
      border: none;
      background-color: transparent;
      color: #2c6598;
      display: flex;
      align-items: flex-end;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 105.18%;
      margin-bottom: 4px;
      letter-spacing: -0.03em;
      color: #2c6598;
    }
  }
`;

export const Container = styled.div `
  width: 300px;
  & p {
      width: 100%;
      color: #656565;
      margin: 0 auto;
      text-align: center;
  }
`

export const MyDataInput = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid #2c6598;
  height: 35px;
  width: 100%;

  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 20%;
    margin-bottom: 4px;
    img {
    }
  }
  & > input {
    margin-bottom: 4px;
    border: none;
    width: 90%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 105.18%;
    letter-spacing: -0.03em;
    color: #2c6598;

    &::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 105.18%;
      margin-bottom: 4px;
      letter-spacing: -0.03em;
      color: #2c6598;
    }
  }
`;