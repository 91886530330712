import { useEffect, useState } from "react";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import MarriedIcon from "../../../images/married.svg";
import MarriedDisabledIcon from "../../../images/married_disabled.svg";
import PersonIconBlue from "../../../images/person_blue.svg";
import PersonIconWhite from "../../../images/person_white.svg";
import TwoPersonIcon from "../../../images/two_person.svg";
import TwoPersonDisabledIcon from "../../../images/two_person_disabled.svg";
import { CivilStateSelector, CivilStateSingleSelector } from "./styles";

type InputProps = {
  type: InputTypes;
  label: string;
  icon: string;
  placeholder: string;
  onAction: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};
export const CivilStateInput = (props: InputProps) => {
  const { addLead, lead } = useLeads();

  const [civilStateSelected, setCivilStateSelected] = useState<string>(
    "" as string
  );

  useEffect(() => {
    if (lead.status_civil) {
      setCivilStateSelected(lead.status_civil);
    }
  }, [lead.status_civil]);

  return (
    <CivilStateSelector>
      <CivilStateSingleSelector
        isSelected={civilStateSelected === "solteiro" ? true : false}
        onClick={() => {
          setCivilStateSelected("solteiro");
          addLead({
            status_civil: "solteiro",
          });
        }}
      >
        <div>
          <img
            src={
              civilStateSelected === "solteiro"
                ? PersonIconWhite
                : PersonIconBlue
            }
            alt=""
          />
        </div>

        <span>Solteiro</span>
      </CivilStateSingleSelector>
      <CivilStateSingleSelector
        isSelected={civilStateSelected === "União estável" ? true : false}
        onClick={() => {
          setCivilStateSelected("União estável");
          addLead({
            status_civil: "União estável",
          });
        }}
      >
        <div>
          <img
            src={
              civilStateSelected === "União estável"
                ? TwoPersonDisabledIcon
                : TwoPersonIcon
            }
            alt=""
          />
        </div>
        <span>União estável</span>
      </CivilStateSingleSelector>
      <CivilStateSingleSelector
        isSelected={civilStateSelected === "casado" ? true : false}
        onClick={() => {
          setCivilStateSelected("casado");
          addLead({
            status_civil: "casado",
          });
        }}
      >
        <div>
          <img
            src={
              civilStateSelected === "casado"
                ? MarriedIcon
                : MarriedDisabledIcon
            }
            alt=""
          />
        </div>
        <span>Casado</span>
      </CivilStateSingleSelector>
    </CivilStateSelector>
  );
};
