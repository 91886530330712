import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 65px;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 28px;
    font-family: Open Sans;
    font-size: 18px;
    text-align: left;
    color: #2c6598;
    background: none;
    text-align: left;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
  }

  h2 {
    margin-top: 28px;
    font-family: Open Sans;
    font-size: 18px;
    text-align: left;
    color: #2c6598;
    pointer-events: none;
  }
`;
