import axios from "axios";
import { useLeads } from "../hooks/leads/leadsHook";
import { Dispatch } from "../redux/store";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export async function getReport(type: string) {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });
    const res = await api.get(`/leads/${type}`, {
      params: { accounts: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID },
      responseType: "blob",
    });
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return res.data;
  } catch (e) {
    Dispatch({
      type: "show_loader",
      show: false,
    });
    console.log(e);
    return false;
  }
}
export async function updateEvent(data: any) {
  try {
    console.log(data);
    const response = await api.post(`/events`, {
      ...data,
      subAccountId: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID,
    });
    return response.data.eventId;
  } catch (e) {
    console.log(e);
  }
}
export async function creditCardPayment(
  cpf: string | undefined,
  leadId: string,
  value: string | undefined,
  data: any
) {
  if (value) {
    // const [, newValue] = value?.split(" ");
    // console.log("api-creditCardpayment", value?.split(" "));
    // const amount = parseInt(value.replace(",", ""));
    // console.log("api-creditCardpayment", amount);
    const metaData = {
      cpf: cpf,
      amount: value,
      leadId: leadId,
      cardNumber: data.cardNumber,
      cardName: data.cardName,
      cardSecurity: data.cardCvc,
      cardBrand: data.cardBrand,
      cardExpiry: data.cardValidity,
    };
    console.log(metaData);
    try {
      Dispatch({
        type: "show_loader",
        show: true,
      });
      const res = await api.post("/checkout", metaData);
      Dispatch({
        type: "show_loader",
        show: false,
      });
      return res.data;
    } catch (e: any) {
      console.log(e);
    }
  }
}

export async function bankslipPayment(leadId: string) {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });
    const res = await api.get(`bankslip/${leadId}`);
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return res.data;
  } catch (e) {
    Dispatch({
      type: "show_loader",
      show: false,
    });
    console.log(e);
    return false;
  }
}
