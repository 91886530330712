import { useEffect, useState } from "react";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import FemaleIcon from "../../../images/female.svg";
import FemaleDisabledIcon from "../../../images/female_disabled.svg";
import MaleIcon from "../../../images/male.svg";
import MaleDisabledIcon from "../../../images/male_disabled.svg";
import NeutralGenderDisabledIcon from "../../../images/married_disabled.svg";
import NeutralGenderIcon from "../../../images/married.svg";
import { GenreBox, GenreSelector } from "./styles";

type InputProps = {
  type: InputTypes;
  label: string;
  icon: string;
  placeholder: string;
  onAction: (e: string) => void;
};
export const GenreSelectorInput = (props: InputProps) => {
  const { addLead, lead } = useLeads();
  const [sex, setSex] = useState<string>("" as string);
  useEffect(() => {
    if (lead.sex) setSex(lead.sex);
  }, [lead.sex]);

  return (
    <GenreSelector>
      <GenreBox
        isSelected={sex === "M" ? true : false}
        onClick={() => {
          setSex("M");

          addLead({
            sex: "M",
          });
        }}
      >
        <div>
          <img
            src={sex === "M" ? MaleIcon : MaleDisabledIcon}
            alt=""
            style={{ width: "26px" }}
          />
        </div>
        <span>Masculino</span>
      </GenreBox>
      <GenreBox
        isSelected={sex === "F" ? true : false}
        onClick={() => {
          setSex("F");
          addLead({
            sex: "F",
          });
        }}
      >
        <div>
          <img src={sex === "F" ? FemaleIcon : FemaleDisabledIcon} alt="" />
        </div>
        <span>Feminino</span>
      </GenreBox>
      <GenreBox
        isSelected={sex === "O" ? true : false}
        onClick={() => {
          setSex("O");
          addLead({
            sex: "O",
          });
        }}
      >
        <div>
          <img src={sex === "O" ? NeutralGenderIcon : NeutralGenderDisabledIcon} alt="" />
        </div>
        <span>Outros</span>
      </GenreBox>
    </GenreSelector>
  );
};
