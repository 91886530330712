import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLeads } from "../../hooks/leads/leadsHook";
import {
  Pages,
  PlansTypes,
} from "../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { bankslipPayment, creditCardPayment } from "../../services/api";
import {
  testDate,
  validateCellphone,
  validateCep,
  validateCpf,
  validateEmail,
  yearsOld,
} from "../../utils/utils";
import {
  BackButton,
  ContentMyData,
  DefaultMasterPageContainer,
  DefaultMasterPageFooter,
  DefaultMasterPageNavHeader,
  NavSelector,
} from "./styles";
type IDefaultMasterPage = {
  children: any;
};
export const DefaultMasterPage = (props: IDefaultMasterPage) => {
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { client, currentPage, lastPage, nextPage } = useStylesController();
  const {
    lead,
    dependent,
    confirm,
    updateDependents,
    paymentData,
    paymentMethod,
    leadId,
    setLoading,
  } = useLeads();
  const [currentPlan, setCurrentPlan] = useState<{
    price: string;
    name: string;
  }>({} as { price: string; name: string });

  const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `
      <br/>
      <p>${msg}</p>`,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };
  const validateField = (label: string) => {
    console.log(`validateForm: LABEL -> ${label}`);
    // console.log(lead.hasOwnProperty(label));
    if (label === "name") {
      if (!lead.name || lead.name.length < 10) {
        return "Informe o nome completo";
      }
    }
    if (label === "cpf") {
      if (!lead.cpf || !validateCpf(lead.cpf.replace(/\D/g, ""))) {
        return "CPF inválido.";
      }
    }
    if (label === "dependent") {
      for (const dep of dependent) {
        if (dep.name && dep.name.length < 10) {
          return "Informe o nome completo";
        }
        if (dep.cpf && !validateCpf(dep.cpf.replace(/\D/g, ""))) {
          return "CPF inválido.";
        }
        if (
          dep.cellphone &&
          !validateCellphone(dep.cellphone.replace(/\D/g, ""))
        ) {
          return "Celular inválido!";
        }
      }
    }
    if (label === "civil_state") {
      if (!lead.status_civil) {
        return "Estado civil inválido.";
      }
    }
    if (label === "address_cep") {
      if (!lead.address_cep || !validateCep(lead.address_cep, "")) {
        return "CEP inválido.";
      }
    }
    if (label === "address_street") {
      if (!lead.address_street || lead.address_street === "") {
        return "Verifique o endereço informado";
      }
    }
    if (label === "salary") {
      if (!lead.salary || lead.salary === "") {
        return "Reda mensal não informada.";
      }
    }
    if (label === "email") {
      if (!lead.email || !validateEmail(lead.email)) {
        return "Email inválido.";
      }
    }
    if (label === "cellphone") {
      if (
        !lead.cellphone ||
        !validateCellphone(lead.cellphone.replace(/\D/g, ""))
      ) {
        return "Celular inválido!";
      }
    }
    if (label === "birthday") {
      if (yearsOld(lead.birthday) < 18 || yearsOld(lead.birthday) > 80) {
        return "Idade não permitida.";
      }

      if (!lead.birthday || testDate(lead.birthday) < 0) {
        return "Data de nascimento inválida!";
      }
    }

    if (label === "genre") {
      if (!lead.sex || lead.sex === "") {
        return "Gênero não informado.";
      }
    }

    if (label === "profession") {
      if (!lead.profession || lead.profession === "") {
        return "Profissão não informada.";
      }
    }
  };

  const validateForm = async (e: any) => {
    e.preventDefault();
    if (currentPage.page === Pages.PAGAMENTO) {
      if (!confirm) {
        alertOpen("Confirme as regras para prosseguir");
        return;
      }
      if (paymentMethod === "credicard") {
        if (!paymentData.cardBrand) {
          alertOpen("Selecione uma bandeira");
          return;
        }
        if (!paymentData.cardNumber) {
          alertOpen("Informe o número do cartão");
          return;
        }
        if (!paymentData.cardName) {
          alertOpen("Informe o nome do cartão");
          return;
        }
        if (!paymentData.cardValidity) {
          alertOpen("Informe a data de validade do cartão");
          return;
        }
        if (!paymentData.cardCvc) {
          alertOpen("Informe o código de segurança do cartão");
          return;
        }
      }
    }
    if (currentPage.page === Pages.MEUS_DADOS) {
      //console.log(`nextPage: ${JSON.stringify(nPage.section)}`);
      if (currentPage.section) {
        for (const s of currentPage.section) {
          if (s.form) {
            for (const f of s.form) {
              if (f.label) {
                const msg = validateField(f.label);
                if (msg) {
                  alertOpen(msg);
                  return;
                }
              }

              if (f.label === "dependent") {
                updateDependents();
              }
            }
            nextPage(history);
          }
        }
      }
    }
    if (!paymentMethod) {
      alertOpen("Selecione o método de pagamento desejado");
      return;
    }
    if (paymentMethod === "credicard") {
      setLoading(true);
      const res = await creditCardPayment(
        lead.cpf,
        leadId,
        lead.product_price,
        paymentData
      );
      setLoading(false);
      console.log(res);
      if (res.success) {
        history.push(`/sucesso/${leadId}`);
      }
      if (!res.success) {
        history.push(`/falha/${leadId}`);
      }
    }
    if (paymentMethod === "bankslip") {
      setLoading(true);
      const response = await bankslipPayment(leadId);
      setLoading(false);
      if (response.success) {
        history.push(`/pendente/${leadId}`);
      }
      if (!response.success) {
        history.push(`/falha/${leadId}`);
      }
    }
  };
  useEffect(() => {
    if (lead.selected_product) {
      const [typePlan, plan] = lead.selected_product.split("-");
      const fullTypePlan = client.plans.find((p) => p.name === typePlan);
      if (fullTypePlan) {
        if (
          fullTypePlan.type &&
          fullTypePlan.type === PlansTypes.SINGLE_PLAN &&
          fullTypePlan.price
        ) {
          setCurrentPlan({
            price: fullTypePlan.price,
            name: fullTypePlan.name,
          });
        } else {
          const singlePlan = fullTypePlan?.services.find(
            (s: any) => s.title === plan
          );
          if (singlePlan) {
            singlePlan.price &&
              setCurrentPlan({
                price: singlePlan.price,
                name: fullTypePlan.name,
              });
          }
        }
      }
    }
  }, [lead, client]);

  return (
    <DefaultMasterPageContainer>
      <DefaultMasterPageNavHeader>
        <nav>
          {client.pageOptions.pages.map((p, index) => (
            <>
              {p.label && (
                <NavSelector
                  isSelected={
                    index ===
                    client.pageOptions.pages.findIndex(
                      (p) => p.page === currentPage.page
                    )
                  }
                >
                  <div></div>
                  <span>{p.label}</span>
                </NavSelector>
              )}
            </>
          ))}
        </nav>
        <BackButton
          onClick={() => {
            lastPage(history);
          }}
        >
          {client.pageOptions.masterPage?.backIcon && (
            <img src={client.pageOptions.masterPage?.backIcon} alt="" />
          )}
          <span>Voltar</span>
        </BackButton>
      </DefaultMasterPageNavHeader>
      <ContentMyData>{props.children}</ContentMyData>
      <DefaultMasterPageFooter>
        <div>
          {lead.selected_product && !lead.numberSeries && (
            <>
              <h2>{lead.selected_product}</h2>
              <div>
                <h3>
                  {lead.product_price} <span>/mês</span>
                </h3>

                {window.screen.width < 768 && (
                  <button onClick={validateForm}>
                    <span>Clique para prosseguir</span>
                  </button>
                )}
              </div>
              <button onClick={validateForm}>
                <span>Prosseguir</span>
              </button>
            </>
          )}
          {lead.selected_product &&
            lead.selected_product === "Plano Individual" &&
            lead.numberSeries && (
              <>
                <h2>{currentPlan.name}</h2>
                <div>
                  <h3>
                    <span>1° parcela</span>
                    <>R$ 9,90 </>{" "}
                    <span>, e depois {currentPlan.price}/mês</span>
                  </h3>

                  {window.screen.width < 768 && (
                    <button onClick={validateForm}>
                      <span>Clique para prosseguir</span>
                    </button>
                  )}
                </div>
                <button onClick={validateForm}>
                  <span>Prosseguir</span>
                </button>
              </>
            )}
          {lead.selected_product &&
            lead.selected_product === "Plano Família" &&
            lead.numberSeries && (
              <>
                <h2>{currentPlan.name}</h2>
                <div>
                  <h3>
                    <span>1° parcela</span>
                    <>R$ 49,90 </>{" "}
                    <span>, e depois {currentPlan.price}/mês</span>
                  </h3>

                  {window.screen.width < 768 && (
                    <button onClick={validateForm}>
                      <span>Clique para prosseguir</span>
                    </button>
                  )}
                </div>
                <button onClick={validateForm}>
                  <span>Prosseguir</span>
                </button>
              </>
            )}
        </div>
      </DefaultMasterPageFooter>
    </DefaultMasterPageContainer>
  );
};
