import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import BemMaisLogoColored from "../../../../images/bem_mais_logo_colored.png";
import {
  Box,
  BoxBackCard,
  CardBack,
  CardFront,
  DataInfo,
  DivNoPrint,
  FooterBack,
  Info,
  Label,
  Logo,
  TextBottom,
  Title,
} from "./styles";
import ButtonTextLink from "../../../../components/ButtonTextLink";

const printCard = (e: any) => {
  e.preventDefault();
  window.print();
};

export const CardDefault = () => {
  const params: any = useParams();
  const { getLead } = useLeads();
  const [lead, setLead] = useState<any>();

  useEffect(() => {
    getLead(params.id).then((data: any) => {
      setLead({
        ...data,
        birthday: format(parseISO(`${data.birthday}`), "dd/MM/yyyy"),
        cpf: data.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4"),
      });
      console.log(data);
    });
  }, [getLead, params.id]);

  return (
    <>
      <Box>
        <CardFront>
          {/*  <img src={CardBackground} alt="" /> */}
          <Logo>
            <img src={BemMaisLogoColored} alt="" />
          </Logo>

          <TextBottom>
            <Title>{lead && lead.name}</Title>
          </TextBottom>
        </CardFront>
        <CardBack>
          <BoxBackCard>
            <DataInfo>
              <Label>CPF</Label>
              <Info>{lead && lead.cpf}</Info>
            </DataInfo>
            <DataInfo>
              <Label>Nascimento</Label>
              <Info>{lead && lead.birthday}</Info>
            </DataInfo>
          </BoxBackCard>
          <BoxBackCard>
            <DataInfo>
              <Label>Plano</Label>
              <Info>{lead && lead.selected_product}</Info>
            </DataInfo>
          </BoxBackCard>

          <FooterBack>
            <div>
              <Label>Central de Atendimento</Label>
              <Info>0800 942 3040</Info>
            </div>
          </FooterBack>
        </CardBack>

        <br />
        <br />
      </Box>
      {lead &&
        lead.dependents &&
        lead.dependents.map((l: any) => {
          return (
            <Box>
              <CardFront>
                {/*  <img src={CardBackground} alt="" /> */}
                <Logo>
                  <img src={BemMaisLogoColored} alt="" />
                </Logo>

                <TextBottom>
                  <Title>{l && l.name}</Title>
                </TextBottom>
              </CardFront>
              <CardBack>
                <BoxBackCard>
                  <DataInfo>
                    <Label>CPF</Label>
                    <Info>{l && l.cpf}</Info>
                  </DataInfo>
                </BoxBackCard>
                <BoxBackCard>
                  <DataInfo>
                    <Label>Plano</Label>
                    <Info>{lead && lead.selected_product}</Info>
                  </DataInfo>
                </BoxBackCard>

                <FooterBack>
                  <div>
                    <Label>Central de Atendimento</Label>
                    <Info>0800 942 3040</Info>
                  </div>
                </FooterBack>
              </CardBack>
              <br />
              <br />
            </Box>
          );
        })}
      <DivNoPrint>
        <ButtonTextLink onClick={printCard} text={"Imprimir"} />
      </DivNoPrint>
    </>
  );
};
