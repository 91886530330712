import styled from "styled-components";

export const ButtonHeader = styled.button`
  border: 0;
  background: none;
  width: 160px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #f8f8ff;
  border-radius: 12px;
  color: #f8f8ff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #f8f8ff;
    color: rgba(149, 22, 185, 1);
  }
`;

export const ButtonFooter = styled.button`
  border: 0;
  background: rgba(149, 22, 185, 1);
  color: #fff;
  width: 160px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;

  border-radius: 12px;

  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid rgba(149, 22, 185, 1);

  &:hover {
    background: #fff;
    color: rgba(149, 22, 185, 1);
    border: 2px solid rgba(149, 22, 185, 1);
  }
`;
export const ContainerSorteio = styled.div`
  text-align: center;

  h3 {
    color: #656565;
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    color: #656565;
    font-weight: normal;
    margin: 5px 0;
  }
`;
export const FaqContainer = styled.div`
  text-align: center;
  strong {
    text-decoration: underline;
  }
`;

export const MailContainer = styled.div`
  text-align: center;
  strong {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    & > a {
      font-size: 3vw;
    }
  }
`;

export const PhoneContainer = styled.div`
  text-align: center;

  div {
    display: flex;
    align-items: center;
    width: 190px;
  }
  a > strong {
    text-decoration: underline;
  }
`;
export const Container = styled.div`
  //margin: 10px auto;
  //border: 4px solid  rgba(149, 22, 185, 1);

  background: #fefefe;
  max-width: 1440px;
  //border-radius: 10px;

  h1 {
    color: rgba(149, 22, 185, 1);
  }

  header {
    background: rgba(149, 22, 185, 1);
    margin-bottom: 50px;
    img {
      max-width: 180px;
      display: block;
      margin: 10px auto;
      margin-right: auto;
    }
    div {
      padding: 46px 0;
      letter-spacing: -4px;
      max-width: 327px;
      margin: 0 auto;
      text-align: center;
    }

    p {
      font-size: 55px;

      color: #fff;
      font-weight: 800;
      margin: 30px 0;

      span {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: normal;
      }
    }

    h3 {
      margin: 0;
      color: #fff;
      font-size: 24px;
      line-height: 27px;
      border-bottom: 2px solid rgb(255 255 255 / 30%);
      padding-bottom: 20px;
      letter-spacing: normal;
    }
  }

  .closeModal {
    position: absolute;
    top: -30px;
    right: 0;
    color: rgba(149, 22, 185, 1);
    font-size: 16px;
    font-weight: bold;

    @media (min-width: 600px) and (max-width: 1366px) {
      right: 30px;
    }
  }

  main {
    max-width: 780px;
    margin: 0 auto;
    text-align: left;
  }

  h4 {
    font-size: 25px;
    color: rgba(149, 22, 185, 1);
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .cta {
    text-align: center;
    max-width: 327px;
    margin: 0 auto;
    padding-bottom: 10px;
    color: rgba(149, 22, 185, 1);

    p {
      font-size: 55px;
      font-weight: 800;
      letter-spacing: -4px;
      margin: 30px 0;

      span {
        font-size: 18px;
        letter-spacing: 0;
      }
    }
  }

  .row-item {
    border: 2px solid #efefef;
    box-sizing: border-box;
    border-radius: 12px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 24px 34px;

    margin-bottom: 16px;
    margin: 10px;
    i {
      flex: 1;
      max-width: 120px;
      margin-right: 34px;
      img {
        width: 100px;
      }
    }

    .info {
      flex: 3;
      padding-right: 50px;
      min-width: 250px;
      h3 {
        color: #656565;
        font-size: 20px;
        margin: 0;
      }
      p {
        color: #656565;
        font-size: 12px;
        font-weight: normal;
        margin: 5px 0;
        text-align: justify;
      }
    }
    .price-info {
      flex: 1;
      color: #5392cb;
      font-size: 18px;
      font-weight: bold;
      min-width: 100px;

      strong {
        font-size: 40px;
        font-weight: 800;
      }
    }
  }
`;
