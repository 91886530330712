import React from "react";
import { ButtonStyled } from "./styles";

interface IButtonTextLink {
  text: string;
  onClick?: any;
  backgroudColor?: string;
  border?: string;
  fontSize?: string;
  fontColor?: string;
  fontColorHover?: string;
}
const ButtonTextLink = (props: IButtonTextLink) => {
  return (
    <ButtonStyled
      backgroudColor={props.backgroudColor}
      border={props.border}
      fontSize={props.fontSize}
      fontColor={props.fontColor}
      fontColorHover={props.fontColorHover}
      onClick={props.onClick}
    >
      {props.text}
    </ButtonStyled>
  );
};

export default ButtonTextLink;
