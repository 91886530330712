import styled from "styled-components";

interface ButtonProps {
  backgroudColor?: string;
  border?: string;
  fontSize?: string;
  fontColor?: string;
  fontColorHover?: string;
}

export const ButtonStyled = styled.button<ButtonProps>`
  border: ${(props) => (props.border ? props.border : "none")};
  background-color: ${(props) =>
    props.backgroudColor ? props.backgroudColor : "transparent"};
  color: ${(props) => (props.fontColor ? props.fontColor : "#656565")};
  cursor: pointer;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  margin: 0 auto;
  padding-bottom: 25px;

  :hover {
    color: ${(props) => (props.fontColorHover ? props.fontColorHover : "#000")};
  }

  @media print {
    visibility: hidden;
  }
`;
