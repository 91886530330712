import { differenceInDays, differenceInYears, parse } from "date-fns";
import {
  ISinglePageOption,
  Pages,
} from "../hooks/stylesController/stylesController.interface";

export const validateCellphone = (cellphone: any) => {
  const regex = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;
  return regex.test(cellphone);
};

export const validateEmail = (email: any) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function Trim(strTexto: string) {
  // Substitúi os espaços vazios no inicio e no fim da string por vazio.
  return strTexto.replace(/^s+|s+$/g, "");
}

export const validateCep = (strCEP: string, blnVazio: string) => {
  // Caso o CEP não esteja nesse formato ele é inválido!
  // var objER = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;

  strCEP = Trim(strCEP.replace(/\D/g, ""));

  if (
    strCEP.length !== 8 ||
    strCEP === "00000000" ||
    strCEP === "11111111" ||
    strCEP === "22222222" ||
    strCEP === "33333333" ||
    strCEP === "44444444" ||
    strCEP === "55555555" ||
    strCEP === "66666666" ||
    strCEP === "77777777" ||
    strCEP === "88888888" ||
    strCEP === "99999999"
  )
    return false;
  if (strCEP.length > 0) {
    // if (objER.test(strCEP)) return true;
    return true;
  } else return blnVazio;
};

export const testDate = (date: any) => {
  return differenceInDays(new Date(), parse(date, "dd/MM/yyyy", new Date()));
};

//console.log(testDate);
export const yearsOld = (birthday: any) => {
  return differenceInYears(
    new Date(),
    parse(birthday, "dd/MM/yyyy", new Date())
  );
};

export const validateCpf = (cpf: any) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const masterPageHeaderConverter = (
  pages: Array<ISinglePageOption>
): Array<{ label: string; page: Pages }> => {
  const pagesCounter: Array<{ label: string; page: Pages }> = [] as Array<{
    label: string;
    page: Pages;
  }>;
  const existPlan = pages.find((p) => p.page === Pages.PLANOS);
  pages.forEach((page) => {
    if (page.page) {
      if (page.page === Pages.HOME && !existPlan) {
        pagesCounter.push({ label: "Planos", page: Pages.HOME });
      }
      if (page.page === Pages.PLANOS) {
        pagesCounter.push({ label: "Planos", page: Pages.PLANOS });
      }
      if (page.page === Pages.MEUS_DADOS) {
        pagesCounter.push({ label: "Meus dados", page: Pages.MEUS_DADOS });
      }
      if (page.page === Pages.DEPENDENTE) {
        pagesCounter.push({ label: "Dependentes", page: Pages.DEPENDENTE });
      }
      if (page.page === Pages.PAGAMENTO) {
        pagesCounter.push({ label: "Pagamento", page: Pages.PAGAMENTO });
      }
    }
  });
  return pagesCounter;
};