import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FooterColored } from "../../../../components/footer/footerColored/footer";
import { Input } from "../../../../components/input/input";
import { WppButton } from "../../../../components/WppButton";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import { IPlans } from "../../../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import BemMaisLogoColored from "../../../../images/bem_mais_logo_white.png";
import CashIcon from "../../../../images/cash.svg";
import CicleIcon from "../../../../images/cicle.svg";
import MedalIcon from "../../../../images/medal.svg";
import MessagePlusIcon from "../../../../images/message_plus.svg";
import ShieldIcon from "../../../../images/shield.svg";
import { request } from "../../../../services/request";
import {
  testDate,
  validateCellphone,
  validateCep,
  validateCpf,
  validateEmail,
  yearsOld,
} from "../../../../utils/utils";
import {
  Benefits,
  BenefitsContent,
  CardPlan,
  Container,
  ContainerPlan,
  ContentPrice,
  Header,
  HeaderLogo,
  HeaderRectangle,
  HeaderTitle,
  MyDataForm,
  PrimaryButton,
  SecondButton,
  SectionContent,
  SubSectionTitle,
} from "./styles";

export const HomeSeller = (homeProps: any) => {
  const MySwal = withReactContent(Swal);
  const {} = useStylesController();
  let [productSelected, setProductSelected] = useState({ name: "" });
  let [colorIndividual, setColorIndividual] = useState({
    back: "#9516b9",
    text: "#fff",
  });
  let [colorFamily, setColorFamily] = useState({
    back: "#9516b9",
    text: "#fff",
  });
  const { currentPage, nextPage, client } = useStylesController();
  const { lead, addLead, dependent, updateDependents, sendLead } = useLeads();
  const [sellers, setSellers] = useState<any>({});
  const [pages] = useState([
    client.plans,
    client.pageOptions.pages[6],
    client.pageOptions.pages[7],
  ] as any);

  const history = useHistory();
  const { handleFluxo } = useStylesController();

  const getSellers = async () => {
    const sellers = await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `sellers/all`,
    });
    if (sellers) {
      setSellers(sellers);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleFluxo("vendedor");
    async function getSellersOnCreateComponent() {
      await getSellers();
    }
    getSellersOnCreateComponent();
  }, []);

  useEffect(() => {
    if (productSelected.name === "Plano Família") {
      setColorIndividual({ back: "#9516b9", text: "#fff" });
      setColorFamily({ back: "#fff", text: "#9516b9" });
    } else if (productSelected.name === "Plano Individual") {
      setColorFamily({ back: "#9516b9", text: "#fff" });
      setColorIndividual({ back: "#fff", text: "#9516b9" });
    }
    console.log("sim");
  }, [productSelected.name]);

  const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `
      <br/>
      <p>${msg}</p>`,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };

  const addPlanToLead = async (plan: IPlans) => {
    if (productSelected.name === "") {
      const [, value] = plan.price.split(' ')
      addLead({
        selected_product: plan.name,
        product_price: plan.price,
        product_value: value,
      });

      setProductSelected({ name: plan.name });
    } else {
      if (plan.name !== productSelected.name) {
        const [, value] = plan.price.split(' ')
        addLead({
          selected_product: plan.name,
          product_price: plan.price,
          product_value: value,
        });
        setProductSelected({ name: plan.name });
      }
      return;
    }
  };

  const isValidSeller = (sellerCode: string) => {
    return sellers.find((seller: any) => seller.sellerCode === sellerCode);
  };

  const validateField = async (e: any) => {
    e.preventDefault();
    console.log(lead);

    if (!lead.selected_product || lead.selected_product === "") {
      return alertOpen("Selecione um plano");
    }

    if (!lead.name || lead.name.length < 10) {
      return alertOpen("Informe o nome completo");
    }
    if (!lead.email || !validateEmail(lead.email)) {
      return alertOpen("Email inválido.");
    }
    if (
      !lead.cellphone ||
      !validateCellphone(lead.cellphone.replace(/\D/g, ""))
    ) {
      return alertOpen("Celular inválido!");
    }

    if (!lead.cpf || !validateCpf(lead.cpf.replace(/\D/g, ""))) {
      return alertOpen("CPF inválido.");
    }
    if (!lead.birthday || testDate(lead.birthday) < 0) {
      return alertOpen("Data de nascimento inválida!");
    }

    if (yearsOld(lead.birthday) < 18 || yearsOld(lead.birthday) > 80) {
      return alertOpen("Idade não permitida.");
    }

    if (!lead.sex || lead.sex === "") {
      return alertOpen("Gênero não informado.");
    }

    if (!lead.address_cep || !validateCep(lead.address_cep, "")) {
      return alertOpen("CEP inválido.");
    }

    if (!lead.address_street || lead.address_street === "") {
      return alertOpen("Verifique o endereço informado");
    }

    if (!lead.seller_code || !isValidSeller(`${lead.seller_code}`)) {
      return alertOpen("Código do vendedor inválido.");
    }

    /*  for (const dep of dependent) {
        if (dep.name && dep.name.length < 10) {
          return "Informe o nome completo";
        }
        if (dep.cpf && !validateCpf(dep.cpf.replace(/\D/g, ""))) {
          return "CPF inválido.";
        }
        if (
          dep.cellphone &&
          !validateCellphone(dep.cellphone.replace(/\D/g, ""))
        ) {
          return "Celular inválido!";
        }
      } */

    await sendLead();
    history.push("/pagamento");
  };

  return (
    <Container>
      <Header>
        <HeaderLogo>
          <img src={BemMaisLogoColored} alt="" />
        </HeaderLogo>
        <HeaderTitle>Você merece muito mais!</HeaderTitle>
        <HeaderRectangle> </HeaderRectangle>
        <Benefits>Benefícios:</Benefits>
        <BenefitsContent>
          <div className="info">
            <img src={MessagePlusIcon} alt="" />
            <h5>
              Atendimento <br />
              médico via <br />
              Telemedicina 24h/7 <br />
              dias por semana
            </h5>
          </div>
          <div className="info">
            <img src={MedalIcon} alt="" />
            <h5>
              Descontos de até 80% <br />
              em farmácias,
              <br /> exames laboratoriais
              <br /> e de imagem
            </h5>
          </div>
          <div className="info">
            <img src={CashIcon} alt="" />
            <h5>
              Sorteios mensais
              <br /> no valor de <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div>
          <div className="info">
            <img src={ShieldIcon} alt="" />
            <h5>
              Seguro de acidentes
              <br /> pessoais por morte <br />
              <strong>R$ 10.000,00</strong>
            </h5>
          </div>
          <div className="info">
            <img src={CicleIcon} alt="" />
            <h5>
              Seguro funeral <br />
              para titular da <br />
              apólice
            </h5>
          </div>
        </BenefitsContent>
      </Header>

      <ContainerPlan>
        {client.plans.map((plan: IPlans) => {
          return (
            <CardPlan>
              <SubSectionTitle>{plan.name}</SubSectionTitle>
              <div className="options">
              <ul>
                <li>Morte Acidental</li>
                <li>Desconto em Exames</li>
                <li>Desconto em Medicamentos</li>
                <li>Sorteio Mensal</li>

                {plan.name === "Plano Família" && (
                  <li>
                    <li>Auxílio Funeral</li>
                    <li>Orientação e apoio Psicológico</li>
                    <li>Desconto Odontológico</li>
                    <strong> Você e mais 3 dependentes</strong>
                  </li>
                )}
                {plan.name === "Plano Individual" && (
                  <li>
                      <li>Auxílio Funeral</li>
                      <li>Orientação e apoio Psicológico</li>
                      <li>Desconto Odontológico</li>
                    <strong>Sem dependentes</strong>
                  </li>
                )}
              </ul>
              </div>
              <ContentPrice>
                <div>
                  <span>Apenas</span>
                  <h3>
                    {plan.price}
                    <span>/mês</span>
                  </h3>
                </div>
                {plan.name === "Plano Família" && (
                  <PrimaryButton
                    style={{
                      backgroundColor: colorFamily.back,
                      color: colorFamily.text,
                    }}
                    onClick={(e: any) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      e.preventDefault;
                      addPlanToLead(plan);
                    }}
                  >
                    Selecionar
                  </PrimaryButton>
                )}
                {plan.name === "Plano Individual" && (
                  <PrimaryButton
                    style={{
                      backgroundColor: colorIndividual.back,
                      color: colorIndividual.text,
                    }}
                    onClick={(e: any) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      e.preventDefault;
                      addPlanToLead(plan);
                    }}
                  >
                    Selecionar
                  </PrimaryButton>
                )}
              </ContentPrice>
            </CardPlan>
          );
        })}
      </ContainerPlan>
      <SectionContent>
        <p>Solicite os dados do cliente:</p>

        <div>
          <div className="form">
            <MyDataForm>
              {pages[1].section[1].form.map((f: any, index: any) => {
                let v: any = lead;
                return (
                  <Input
                    fluxo={f.fluxo || ""}
                    key={f.placeholder}
                    type={f.type}
                    label={f.label ? f.label : ""}
                    icon={f.icon ? f.icon : ""}
                    placeholder={f.placeholder ? f.placeholder : ""}
                    value={v[`${f.label}`]}
                    onAction={(e) => {
                      console.log(`${f.label}:${e.target.value}`);
                      addLead({ [`${f.label}`]: e.target.value });
                      // if (f.label === "address_cep") {
                      //   LeadsProvider(f.label)}
                    }}
                  />
                );
              })}
            </MyDataForm>
          </div>
          <div className="form">
            <MyDataForm>
              {pages[2].section[1].form.map((f: any, index: any) => {
                let v: any = lead;
                return (
                  <Input
                    fluxo={f.fluxo || ""}
                    key={f.placeholder}
                    type={f.type}
                    label={f.label ? f.label : ""}
                    icon={f.icon ? f.icon : ""}
                    placeholder={f.placeholder ? f.placeholder : ""}
                    value={v[`${f.label}`]}
                    onAction={(e) => {
                      console.log(f.label + ": " + e.target.value);
                      addLead({ [`${f.label}`]: e.target.value });
                    }}
                  />
                );
              })}
            </MyDataForm>
          </div>
        </div>
        <SecondButton onClick={validateField}>
          <span>Prosseguir</span>
        </SecondButton>
      </SectionContent>

      <FooterColored />
    </Container>
  );
};
