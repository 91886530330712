import React from "react";
import { LeadsProvider } from "./leads/leadsHook";
import { StylesControllerProvider } from "./stylesController/stylesControllerHook";

const AppProvider: React.FC = ({ children }) => (
  <LeadsProvider>
    <StylesControllerProvider>{children}</StylesControllerProvider>
  </LeadsProvider>
);

export default AppProvider;
