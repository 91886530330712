import styled from "styled-components";

export const InputRowStyled = styled.div`
  position: relative;
  span {
    background: url(${(props: { icon?: string }) => props.icon}) no-repeat 0;
    position: absolute;
    bottom: 10px;
    left: 5px;
    background-size: 18px;
    width: 25px;
    height: 25px;
  }
`;

export const InputStyled = styled.input`
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 0;
  border-bottom: 2px solid #2c6598;
  padding: 11px 35px;
  font-size: 20px;
  margin-bottom: 25px;
`;

export const ComponentStyled = styled.body`
  color: #2c6598;

  h2 {
    font-size: 30px;
    padding: 24px 0;
  }

  p {
    font-size: 18px;
    text-align: center;
    padding-bottom: 24px;
  }

  input {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #2c6598;
    padding: 11px 35px;
    font-size: 20px;
    margin-bottom: 25px;
    background: #fff;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: rgb(44 101 152 / 60%);
    }
    :-ms-input-placeholder {
      color: rgb(44 101 152 / 60%);
    }

    &:focus {
      outline: none;
      border-color: rgb(44 101 152 / 30%);
    }
  }
`;

export const DivStyled = styled.div`
  max-width: 325px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 0 auto;
    padding: 15px;
  }
`;
