import { useEffect } from "react";
import { useHistory } from "react-router";
import { updateEvent } from "../../services/api";

export const CheckoutCallbak = () => {
  const history = useHistory();

  const updateEventFail = async (id: string, statusPayment: string) => {
    await updateEvent({
      leadId: id,
      type: statusPayment,
    });
  };
  useEffect(() => {
    let locationSearch = history.location.search.replace("?", "");
    console.log(locationSearch);
    let queryParams: any = {};
    for (const param of locationSearch.split("&")) {
      let paramSplit = param.split("=");
      queryParams[paramSplit[0]] = paramSplit[1];
    }

    if (queryParams.id?.length === 24) {
      switch (queryParams.status) {
        case "success":
          return history.push(`/sucesso/${queryParams.id}`);
        case "pending":
          return history.push({
            pathname: `/pendente/${queryParams.id}`,
            state: { url: queryParams.url },
          });

        case "failure":
          updateEventFail(queryParams.id, "PGTO_FALHA");
          return history.push(`/falha/${queryParams.id}`);
        default:
          updateEventFail(queryParams.id, "PGTO_ERRO");
          return history.push(`/erro/${queryParams.id}`);
      }
    } else {
      updateEventFail(queryParams.id, "PGTO_ERRO");
      return history.push(`/erro/${queryParams.id}`);
    }
  }, []);

  return <></>;
};
