import exames from "../../images/sabemi-produto/exames.png";
import funeral from "../../images/sabemi-produto/funeral.png";
import medicamentos from "../../images/sabemi-produto/medicamentos.png";
import morteAcidental from "../../images/sabemi-produto/morte-acidental.png";
import sorteioMensal from "../../images/sabemi-produto/sorteiomensal.png";
import telemedicina from "../../images/telemedicina.png";
import {
  ButtonFooter,
  ButtonHeader,
  Container,
  FaqContainer,
  MailContainer,
  PhoneContainer,
} from "./styles";
import IconWhatsapp from "../../images/whatsapp-svgrepo-com.svg";

export const PlanDetail = (props: any) => {
  const { addPlanToLead, plan, isHidden } = props;
  return (
    <Container>
      <div>
        {!isHidden && (
          <header>
            <div>
              {/* <img src={option1} alt="Torcedor Protegido" /> */}
              <p>
                <h3>{props.name}</h3>
                R$ {props.price}
                <span>/mês</span>
              </p>
              <ButtonHeader
                id="option1"
                onClick={(e: any) => {
                  props.addPlanToLead(props.plan);
                }}
              >
                Comprar
              </ButtonHeader>
            </div>
          </header>
        )}
        <main>
          <div className="itens">
            <div>
              <div className="row-item">
                <i>
                  <img src={telemedicina} alt="Telemedicina" />
                </i>
                <div className="info">
                  <h3>Telemedicina</h3>
                  <p>
                    Solução de consulta médica através de vídeo ou chamada de
                    telefone. Telemedicina com prescrição médica disponível 24h
                    por dia, 7 dias por semana.
                  </p>
                </div>
              </div>
              {/* <div className="row-item">
                <i>
                  <img src={exames} alt="Exames" />
                </i>
                <div className="info">
                  <h3>Desconto em Exames</h3>
                  <p>
                    O serviço conta com cartão virtual para sua utilização do
                    serviço. Possibilitando dispor dele e realizar a
                    apresentação na utilização do desconto. Principais exames
                    com desconto: Hemograma completo, colesterol total, glicemia
                    de jejum, consulta médica e retorno.
                  </p>
                </div>
              </div> */}
              {/* <div className="row-item">
                <i>
                  <img src={medicamentos} alt="Medicamentos" />
                </i>
                <div className="info">
                  <h3>Desconto em Medicamentos</h3>
                  <p>
                    Aplicativo intuitivo para utilização do desconto,
                    visualização da carteirinha virtual e diversas outras
                    funcionalidades. Principais farmácias: Raia, Pacheco,
                    Drogasil, entre outras redes.
                  </p>
                </div>
              </div> */}
              <div className="row-item">
                <i>
                  <img src={telemedicina} alt="Orientação Psicológica" />
                </i>
                <div className="info">
                  <h3>Orientação e Apoio Psicológico</h3>
                  <p>
                    Serviço totalmente sigiloso, conforme o Código de Ética
                    Profissional do Psicólogo. Acesso via central de atendimento
                    por telefone (0800) e posteriormente atendimento por vídeo
                    agendado com o profissional de psicologia. Atendimento de
                    segunda à sexta das 08:00 às 18:00 horas.
                  </p>
                </div>
              </div>
              {/* <div className="row-item">
                <i>
                  <img src={exames} alt="Desconto Odontológico" />
                </i>
                <div className="info">
                  <h3>Desconto em Consultas e Procedimentos Odontológicos</h3>
                  <p>
                    Para utilização dos benefícios, basta apresentar a
                    carteirinha virtual. Principais procedimentos com desconto:
                    Restauração, Limpeza, Endodontia, Ortodontia, Panorâmica,
                    Tratamento de Canal.
                  </p>
                </div>
              </div> */}

              {/* <div className="row-item">
                <i>
                  <img src={sorteioMensal} alt="Sorteio Mensal" />
                </i>
                <div className="info">
                  <h3>Sorteio mensal de "R$ 10MIL"</h3>
                  <p>
                    Com o pagamento do seguro em dia, o segurado concorrerá a
                    sorteios todos os meses pela Loteria Federal no último
                    sábado de cada mês.
                  </p>
                </div>
              </div> */}
              <div className="row-item">
                <i>
                  <img src={morteAcidental} alt="Morte Acidental" />
                </i>
                <div className="info">
                  <h3>Morte Acidental</h3>
                  <p>
                    Indenização para seus beneficiários em caso de falecimento
                    por acidente coberto.
                  </p>
                </div>
                <div className="price-info">
                  R$<strong>10</strong> mil
                </div>
              </div>
              <div className="row-item">
                <i>
                  <img src={funeral} alt="funeral" />
                </i>
                <div className="info">
                  <h3>Funeral Individual</h3>
                  <p>
                    Garante a prestação do serviço de Assistência Funeral, até o
                    valor de R$ 3.000,00. OS trâmites do funeral do Segurado são
                    providenciados de forma rápida e sem burocracias. A
                    assistência funeral compreende somente a prestação do
                    serviço de funeral, não estando disponível o reembolso das
                    despesas.
                  </p>
                </div>
                <div className="price-info">
                  R$<strong>3</strong> mil
                </div>
              </div>
              <FaqContainer>
                Ficou com alguma dúvida? <br />
                Leia nossas{" "}
                <a
                  rel="noreferrer"
                  href="https://bria-assets.s3.sa-east-1.amazonaws.com/faq.pdf"
                  target="_blank"
                >
                  <strong>perguntas frequentes</strong>
                </a>
                .
              </FaqContainer>
              <br />
              <MailContainer>
                Email <br />
                <a
                  rel="noreferrer"
                  href="mailto:suporte@bemmaisclubedebeneficios.com"
                  // target="_blank"
                >
                  <strong>suporte@bemmaisclubedebeneficios.com</strong>
                </a>
              </MailContainer>

              <PhoneContainer>
                Telefone <br />
                <strong>0800 942 3040</strong>
              </PhoneContainer>
              <br />
              <PhoneContainer>
                Whatsapp
                <br />
                <div>
                  <img src={IconWhatsapp} alt="Whatsapp" height="30px" />
                  <a
                    href="https://wa.me/5524999466598"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>(24) 99946-6598</strong>
                  </a>
                </div>
              </PhoneContainer>
              {!isHidden && (
                <div className="cta">
                  <p>
                    R$ {props.price}
                    <span>/mês</span>
                  </p>
                  <ButtonFooter
                    id="option1"
                    onClick={(e: any) => {
                      addPlanToLead(plan);
                    }}
                  >
                    COMPRAR
                  </ButtonFooter>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Container>
  );
};
