import React from "react";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLeads } from "../../hooks/leads/leadsHook";
import mailIcon from "../../images/email_blue.svg";
import phoneIcon from "../../images/telephone_blue.svg";
import { updateEvent } from "../../services/api";
import getLocation from "../../services/getIp";
import sweetAlert from "../../utils/sweetAlert";
import { validateCellphone, validateEmail } from "../../utils/utils";
import ButtonTextLink from "../ButtonTextLink";
import {
  ComponentStyled,
  DivStyled,
  InputRowStyled,
  InputStyled,
} from "./styles";

interface INeedHelp {}
const NeedHelp = (props: INeedHelp) => {
  const { lead, leadId, stateClean, sendLead, updateLead } = useLeads();
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  const handleUpdate = async () => {
    const location = await getLocation();
    await updateEvent({
      leadId: leadId,
      type: "AJUDA",
      location: location,
    });
  };

  const errorAlertOpen = (msg: any) => {
    MySwal.fire({
      html: `
      <br/>
      <p>${msg}</p>`,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };

  const alertOpen = async (Component: any) => {
    const { value: dataLead } = await MySwal.fire({
      html: Component,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Enviar",
      allowOutsideClick: true,
      showCloseButton: true,
      preConfirm: () => {
        return [
          (document.getElementById("email") as HTMLInputElement).value,
          (
            document.getElementById("cellphone") as HTMLInputElement
          ).value.replace(/\D/g, ""),
        ];
      },
    });

    if (dataLead) {
      const email = dataLead[0];
      const cellphone = dataLead[1];
      if (!validateEmail(email)) {
        errorAlertOpen("Email inválido");
      } else if (!validateCellphone(cellphone)) {
        errorAlertOpen("Telefone inválido");
      } else {
        if (leadId !== "") {
          await updateLead({
            leadId: lead.leadId,
            email,
            cellphone,
            needHelp: true,
          });
        } else {
          await sendLead({
            email,
            cellphone,
            needHelp: true,
          });
        }
        await handleUpdate();
        sweetAlert.success("Entraremos em contato em breve").then(async () => {
          await stateClean();
          history.push("/");
        });
      }
    }
  };

  const Content = () => {
    return (
      <>
        <ComponentStyled>
          <h2>Precisa de Ajuda?</h2>
          <p> Informe os dados abaixo que entraremos em contato.</p>
          <InputRowStyled icon={mailIcon}>
            <span />
            <InputStyled type="text " id="email" placeholder="Email" />
          </InputRowStyled>
          <InputRowStyled icon={phoneIcon}>
            <span />
            <MaskedInput
              mask={[
                "(",
                /\d/,
                /\d/,
                ")",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              id="cellphone"
              type="text"
              placeholder="Celular"
            />
          </InputRowStyled>
        </ComponentStyled>
      </>
    );
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    alertOpen(<Content />);
  };

  return (
    <>
      <DivStyled>
        <ButtonTextLink
          onClick={handleClick}
          text="Precisa de Ajuda?"
        ></ButtonTextLink>
      </DivStyled>
    </>
  );
};

export default NeedHelp;
