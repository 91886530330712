import { InputTypes } from "../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { CivilStateInput } from "./civilStateInput/civilStateInput";
import { DependentInput } from "./dependents/dependentInput";
import { GenreSelectorInput } from "./genreSelectorInput/genreSelectorInput";
import { ProfessionSelectInput } from "./professionSelectInput/professionSelectInput";
import { SalarySelectInput } from "./salarySelectInput/salarySelectInput";
import { SellerInput } from "./sellerCodeInput/sellerInput";
import { TextInput } from "./textInput/textInput";

type InputProps = {
  type: InputTypes;
  label: string;
  icon: string;
  placeholder: string;
  value: string;
  quantity?: number;
  fluxo?: string;
  onAction: (e: any) => void;
};

export const Input = (props: InputProps) => {
  const { fluxo } = useStylesController();

  if (
    props.type === InputTypes.SELLER &&
    fluxo === "confirmesuacompra" &&
    props.fluxo === "confirmesuacompra"
  ) {
    return <SellerInput {...props} />;
  }
  if (props.type === InputTypes.TEXT && !props.fluxo) {
    return <TextInput {...props} />;
  }

  if (props.type === InputTypes.CIVIL_STATE) {
    return <CivilStateInput {...props} />;
  }
  if (props.type === InputTypes.SALARY) {
    return <SalarySelectInput {...props} />;
  }
  if (props.type === InputTypes.DEPENDENT) {
    return <DependentInput {...props} />;
  }
  if (props.type === InputTypes.GENRE_SELECTOR) {
    return <GenreSelectorInput {...props} />;
  }
  if (props.type === InputTypes.PROFESSION_SELECTOR) {
    return <ProfessionSelectInput {...props} />;
  }
  return <></>;
};
