import styled from "styled-components";

export const PlanContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(83, 147, 203, 0.08);
  padding-top: 90px;

  @media (max-width: 768px) {
    div {
      flex-wrap: wrap;
      margin-left: 34px;
    }
  }
`;

export const ContainerPlan = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 45px;
`;

export const CardPlan = styled.div`
  margin: 45px;

  margin-top: 45px;
  padding: 30px;
  border: 2px solid #5392cb;
  border-radius: 12px;

  font-family: Open Sans;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 350px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  ul {
    list-style-type: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    width: 100%;
    letter-spacing: -0.03em;
    /* identical to box height */
    color: #656565;
    li {
      padding-top: 2px;
      strong {
        font-weight: 800;
      }
    }
  }
`;
export const ContentPrice = styled.div`
  width: 100%;
  div {
    margin-top: 60px;

    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #656565;
    }
    & > h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 51.1236px;
      line-height: 70px;
      text-align: left;
      letter-spacing: -0.05em;
      color: #5392cb;
      & > span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 13.1461px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #5392cb;
      }
    }
  }
`;

export const PrimaryButton = styled.button`
  margin-top: 4px;
  cursor: pointer;
  border: 2px solid #9516b9;
  background: #9516b9;
  border-radius: 12px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #fff;
`;

export const SecondaryButton = styled.button`
  margin-top: 4px;
  max-width: 354px;
  width: 100%;
  height: 54px;
  border: 2px solid #5392cb;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 105.18%;

  text-align: center;
  letter-spacing: -0.03em;
  color: #5392cb;
`;

export const SubSectionTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: left;
  color: #5392cb;
  width: 100%;
`;
