import { useEffect } from "react";
import { useLeads } from "../../../../hooks/leads/leadsHook";
import { useStylesController } from "../../../../hooks/stylesController/stylesControllerHook";
import { ThankContainer } from "./styles";

export const ThankDefault = () => {
  const { lead, leadId } = useLeads();
  const { currentPage } = useStylesController();
  useEffect(() => {
    let price = "";
    if (lead.selected_product === "Plano Individual") {
      price = "jtFlyWQUCUzNbrWLnFU+cg==";
    }
    if (lead.selected_product === "Plano Família") {
      price = "dchYvEml0KajYIKS3kjqYw==";
    }
    if (lead.selected_product === "Plano Individual" && lead.numberSeries) {
      price = "KhK9m8jrgSU5eHC2PN3KPQ==";
    }
    if (lead.selected_product === "Plano Família" && lead.numberSeries) {
      price = "Iy7W4w2eF8s62EtbCBgbFQ==";
    }

    const paylabCheckoutBaseUrl =
      "https://comprasegura.serracap.ofertadigital.in/checkout/dadospagamento";

    const cpfNumber = lead.cpf ? lead.cpf.replace(/[^0-9]/g, "") : "";
    const cellphoneNumber = lead.cellphone
      ? lead.cellphone.replace(/[^0-9]/g, "")
      : "";
    const nameWithPlus = lead.name ? lead.name.replace(/ /g, "+") : "";
    const paylabCheckoutUrl = `${paylabCheckoutBaseUrl}/${price}?id=${leadId}&cpf=${cpfNumber}&email=${lead.email}&name=${nameWithPlus}&phone=${cellphoneNumber}`;
    console.log(paylabCheckoutUrl);
    window.location.replace(paylabCheckoutUrl);
  }, [lead, leadId]);

  return (
    <ThankContainer
      backgroundColorMain={
        currentPage.backgroudColorMain && currentPage.backgroudColorMain
      }
      textColorMain={currentPage.textColorMain && currentPage.textColorMain}
    ></ThankContainer>
  );
};
