import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FooterColored } from "../../components/footer/footerColored/footer";
import { Input } from "../../components/input/input";
import { useLeads } from "../../hooks/leads/leadsHook";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import BemMaisLogoColored from "../../images/bem_mais_logo_colored.png";
import PersonalDocumentIconBlue from "../../images/personal_document_blue.svg";
import { InputTypes } from "../../hooks/stylesController/stylesController.interface"
import {
  testDate,
  validateCellphone,
  validateCep,
  validateCpf,
  validateEmail,
  yearsOld,
} from "../../utils/utils";
import {
  Container,
  Header,
  HeaderContent,
  HeaderImage,
  HeaderLogo,
  HeaderTitle,
  MyDataForm,
  SecondButton,
  SectionContent,
} from "./styles";

export const TestTelemedicina = (homeProps: any) => {
  const MySwal = withReactContent(Swal);
  const { client } = useStylesController();
  const { lead, addLead, sendLead } = useLeads();
  const [pages] = useState([
    client.plans,
    client.pageOptions.pages[8],
    client.pageOptions.pages[9],
  ] as any);

  const history = useHistory();
 
   const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `
      <br/>
      <p>${msg}</p>`,
      confirmButtonColor: "#9516B9",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };


  const validateField = async (e: any) => {
    e.preventDefault();

    if (!lead.name || lead.name.length < 10) {
      return alertOpen("Informe o nome completo");
    }
    if (!lead.email || !validateEmail(lead.email)) {
      return alertOpen("Email inválido.");
    }
    if (
      !lead.cellphone ||
      !validateCellphone(lead.cellphone.replace(/\D/g, ""))
    ) {
      return alertOpen("Celular inválido!");
    }

    if (!lead.cpf || !validateCpf(lead.cpf.replace(/\D/g, ""))) {
      return alertOpen("CPF inválido.");
    }
    if (!lead.birthday || testDate(lead.birthday) < 0) {
      return alertOpen("Data de nascimento inválida!");
    }

    if (yearsOld(lead.birthday) < 18 || yearsOld(lead.birthday) > 80) {
      return alertOpen("Idade não permitida.");
    }

    if (!lead.sex || lead.sex === "") {
      return alertOpen("Gênero não informado.");
    }

    if (!lead.address_cep || !validateCep(lead.address_cep, "")) {
      return alertOpen("CEP inválido.");
    }

    if (!lead.address_street || lead.address_street === "") {
      return alertOpen("Verifique o endereço informado");
    }

    if ( lead.promotional_code !== process.env.REACT_APP_PROMOTIONAL_CODE ) {
      return alertOpen("Código promocional inválido.");
    }
    const leadId = await sendLead();
    history.push(`/sucessotelemedicina/${leadId}`);
  };

  return (
    <Container>
     <Header>
        <HeaderImage>
          <HeaderTitle>Ative seu benefício gratuito por 30 dias!</HeaderTitle>
          <HeaderLogo>
            {" "}
            <img src={BemMaisLogoColored} alt="" />
          </HeaderLogo>
        </HeaderImage>
      </Header>
      <HeaderContent>
        <h1>
          Confirme <br />
          sua compra
        </h1>
        <p>
          <strong>Olá</strong>,
          <br /> Você já deu o primeiro passo <br />
          para cuidar da sua saúde com o <br />
          <span>Bem mais Clube de Benefícios!</span>
        </p>
      </HeaderContent>
      <SectionContent>
        <p>
          <strong>
            Preencha seus dados para ativar o benefício de <br />
          </strong>
          <span>Telemedicina!</span>
        </p>
        {/* <Plans /> */}
      </SectionContent>
      <SectionContent>
        <div>
          <div className="form">
            <MyDataForm>
              {pages[1].section[1].form.map((f: any, index: any) => {
                let v: any = lead;
                return (
                  <Input
                    fluxo={f.fluxo || ""}
                    key={f.placeholder}
                    type={f.type}
                    label={f.label ? f.label : ""}
                    icon={f.icon ? f.icon : ""}
                    placeholder={f.placeholder ? f.placeholder : ""}
                    value={v[`${f.label}`]}
                    onAction={(e) => {
                      console.log(`${f.label}:${e.target.value}`);
                      addLead({ [`${f.label}`]: e.target.value });
                      // if (f.label === "address_cep") {
                      //   LeadsProvider(f.label)}
                    }}
                  />
                );
              })}
            </MyDataForm>
          </div>
          <div className="form">
            <MyDataForm>
              {pages[2].section[1].form.map((f: any, index: any) => {
                let v: any = lead;
                return (
                  <Input
                    fluxo={f.fluxo || ""}
                    key={f.placeholder}
                    type={f.type}
                    label={f.label ? f.label : ""}
                    icon={f.icon ? f.icon : ""}
                    placeholder={f.placeholder ? f.placeholder : ""}
                    value={v[`${f.label}`]}
                    onAction={(e) => {
                      console.log(f.label + ": " + e.target.value);
                      addLead({ [`${f.label}`]: e.target.value });
                    }}
                  />
                );
              })}
            </MyDataForm>
          </div>
         
        </div>
            <div className="promotionalCode">
            <Input
                fluxo=''
                key="Código Promocional"
                type={InputTypes.TEXT}
                label="promotional_code"
                icon={PersonalDocumentIconBlue}
                placeholder="Código Promocional"
                value=''
                onAction={(e) => addLead({promotional_code: e.target.value, selected_product: 'Telemedicina'})}
                />
            </div>
        <SecondButton onClick={validateField}>
          <span>Ativar</span>
        </SecondButton>
      </SectionContent>

      <FooterColored />
    </Container>
  );
};
