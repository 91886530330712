import MaskedInput from "react-text-mask";
import { useLeads } from "../../../hooks/leads/leadsHook";
import { InputTypes } from "../../../hooks/stylesController/stylesController.interface";
import { maskRegex } from "./maskRegex";
import { MyDataInput } from "./styles";

type InputProps = {
  mask?: any;
  type: InputTypes;
  label: any;
  icon: string;
  placeholder: string;
  value: string;
  onAction: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export const TextInput = (props: InputProps) => {
  const { lead } = useLeads();

  return (
    <MyDataInput>
      <div>
        <img src={props.icon} alt="" />
      </div>
      <MaskedInput
        mask={maskRegex[props.label] ? maskRegex[props.label] : false}
        type="text"
        name={props.label}
        id={props.label}
        placeholder={props.placeholder}
        defaultValue={lead[props.label]}
        onChange={props.onAction}
      />
    </MyDataInput>
  );
};
