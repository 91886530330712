import QRCode from "react-qr-code";
import GlobalStyle from "../../globalStyled";
import BemMaisLogoColored from "../../images/bem_mais_logo_colored.png";
import { Col } from "./styles";

const LinkCode = () => {
  return (
    <>
      <GlobalStyle />
      <Col>
        <img src={BemMaisLogoColored} alt="" />
        <h1>A sua saúde merece muito mais! </h1>
        <h2>
          Com o Bem Mais Clube de Benefícios você se cuida e a sua saúde e o seu
          bolso agradecem.
        </h2>
        <div className="qrcode">
          <QRCode
            bgColor="#fff"
            fgColor="#000"
            size={180}
            level="L"
            value="http://www.bemmaisclubedebeneficios.com.br"
            title="Oferta Digital"
          />
        </div>{" "}
        <a href="http://www.bemmaisclubedebeneficios.com.br">
          www.bemmaisclubedebeneficios.com.br
        </a>
      </Col>
    </>
  );
};

export default LinkCode;
