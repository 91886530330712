import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 360px;
  }
`;

export const Row = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const Title1 = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #2c6598;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

export const Subtitle1 = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  font-family: Open Sans;
  font-size: 1.4em;
  text-align: center;
  color: #2c6598;
`;

export const Title2 = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  font-weight: bold;
  font-family: Open Sans;
  font-size: 1.2em;
  text-align: center;
  color: #2c6598;
`;

export const Subtitle2 = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;

  font-family: Open Sans;
  font-size: 1em;
  text-align: center;
  color: #2c6598;
`;

export const Box = styled.div`
  margin: 10px;
  width: 350px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  border: 1px solid #2c6598;
  border-radius: 20px;
  div {
    margin: 10px;
  }
`;

export const LeadInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 4px;
  width: 100%;

  span {
    font-family: "Open Sans";
    margin-left: 4px;
  }

  div {
    margin: 4px;
    width: 20px;
    img {
      width: 20px;
      object-fit: contain;
    }
  }
`;

export const PlanInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
  span {
    font-family: "Open Sans";
    margin-left: 4px;
  }

  div {
    padding: 10px;
    margin: 4px;
    background-color: #2c6598;
    border-radius: 8px;
    width: 40px;
    img {
      width: 20px;
      object-fit: contain;
    }
  }
`;

export const ConfirmStyled = styled.div`
  margin: 20px;
  display: flex;
`;

export const NoteStyled = styled.p`
  font-family: "Open Sans";
  width: 300px;
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
  a {
    font-weight: bold;
  }
`;
