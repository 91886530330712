import axios from "axios";
import { format, parse } from "date-fns";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { updateEvent } from "../../services/api";
import getLocation from "../../services/getIp";
import { request } from "../../services/request";

type LeadsProviderProps = {
  children: ReactNode;
};

type ILeadsContextData = {
  addLead: (leadData: SingleLead) => void;
  subscribeLead: () => void;
  getLead: (leadId?: string) => Promise<any>;
  sendLead: (leadData?: SingleLead) => Promise<void>;
  updateLead: (leadData?: SingleLead) => Promise<void>;
  getProfessions: () => Promise<any>;
  stateClean: () => void;
  handleChangePayment: (data: any) => void;
  handleChangePaymentMethod: (data: any) => void;
  setLoading: (data: boolean) => void;
  paymentData: any;
  leadId: string;
  lead: SingleLead;
  addDependent: any;
  dependent: any;
  confirm: boolean;
  handleChange: any;
  updateDependents: any;
  handleLocation: any;
  updateEvent: any;
  paymentMethod: string;
  loading: boolean;
};
export type SingleLead = {
  numberSeries?: string;
  birthday?: string;
  cellphone?: string;
  email?: string;
  leadId?: string;
  profession?: string;
  sex?: string;
  selected_product?: string;
  product_price?: string;
  name?: string;
  cpf?: string;
  seller_code?: string;
  status_civil?: string;
  address_cep?: string;
  address_street?: string;
  address_number?: string;
  address_complement?: string;
  address_city?: string;
  address_neighborhood?: string;
  address_state?: string;
  exposed?: number;
  salary?: string;
  check_roles?: boolean;
  check_bb?: boolean;
  payment_option?: number;
  bank_number?: string;
  bank_account?: string;
  bank_agency?: string;
  eventAccessId?: string;
  protocol?: string;
  protocolDate?: Date;
  [propName: string]: any;
  needHelp?: Boolean;
  product_value?: string;
  paymentMethod?: string;
};

const LeadsContext = createContext<ILeadsContextData>({} as ILeadsContextData);

export const LeadsProvider = ({ children }: LeadsProviderProps) => {
  const [lead, setLead] = useState<SingleLead>({} as SingleLead);
  const [confirm, setConfirm] = useState(false);
  const [paymentData, setPaymentData] = useState({})
  const [leadId, setLeadId] = useState<string>("");
  const [dependent, setDependent] = useState([] as any);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lead.address_cep && lead.address_cep.replace(/\D/g, "").length === 8) {
      axios
        .get(`https://brasilapi.com.br/api/cep/v2/${lead.address_cep}`)
        .then((response: any) => {
          console.log(`Endereço: ${response.data.street}`);
          setLead((prev: SingleLead) => ({
            ...prev,
            address_street: response.data.street,
            address_city: response.data.city,
            address_neighborhood: response.data.neighborhood,
            address_state: response.data.state

          }));
        });
    }
  }, [lead?.address_cep]);

  const addLead = useCallback(async (leadData: SingleLead) => {
    setLead((l) => ({
      ...l,
      ...leadData,
    }));
  }, []);

  const handleChange = useCallback(async () => {
    setConfirm(!confirm);
  }, [confirm]);

  const handleChangePayment = useCallback(async (data: any) => {
    setPaymentData(data);
  }, [paymentData]);

  const handleChangePaymentMethod = useCallback(async (data: any) => {
    setPaymentMethod(data);
  }, [paymentMethod]);

  const getProfessions = async () => {
    return await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `professions`,
    });
  };

  const updateDependents = useCallback(async () => {
    console.log("updateDependents()");
    const data = [];
    for (const dep of dependent) {
      if (dep.cellphone && dep.name && dep.name) {
        data.push({
          name: dep.name,
          cellphone: dep.cellphone.replace(/\D/g, ""),
          cpf: dep.cpf.replace(/\D/g, ""),
        });
      }
    }

    await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "PUT",
      path: `leads/dependents/${leadId}`,
      data: data,
    });
  }, [leadId, dependent]);

  const addDependent = useCallback(
    async (index: any, label: string, value: string) => {
      const newDependent = [...dependent];
      newDependent[index] = { ...newDependent[index], [label]: value };
      setDependent(newDependent);
    },
    [dependent]
  );

  const getLead = useCallback(async (leadId?: string) => {
    const response =  await request({
      showSuccessMessage: false,
      showLoading: true,
      timeout: 240000,
      method: "GET",
      path: `leads/${leadId}`,
    });
    setLeadId(response._id);
    addLead(response);
  }, []);

  const sendLead = useCallback(
    async (leadData?: SingleLead) => {
      if (leadData?.cpf) {
        leadData.cpf = leadData.cpf.replace(/\D/g, "");
      }
      if (leadData?.cellphone) {
        leadData.cellphone = leadData.cellphone.replace(/\D/g, "");
      }
      if (leadData?.address_cep) {
        leadData.address_cep = leadData.address_cep.replace(/\D/g, "");
      }
      if (leadData?.seller_code) {
        leadData.seller_code = leadData.seller_code.replace(/\D/g, "");
      }
      if (leadData?.birthday && leadData.birthday.split("/").length > 1) {
        leadData.birthday = format(
          parse(leadData.birthday, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }

      if (lead?.cpf) {
        lead.cpf = lead.cpf.replace(/\D/g, "");
      }
      if (lead?.cellphone) {
        lead.cellphone = lead.cellphone.replace(/\D/g, "");
      }
      if (lead?.address_cep) {
        lead.address_cep = lead.address_cep.replace(/\D/g, "");
      }
      if (lead?.product_value) {
        lead.product_value = lead.product_value.replace(/\D/g, "");
      }
      if (lead?.birthday && lead.birthday.split("/").length > 1) {
        lead.birthday = format(
          parse(lead.birthday, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }

      console.log("POST leads/serracap");
      const response = await request({
        showSuccessMessage: false,
        showLoading: true,
        timeout: 240000,
        method: "POST",
        path: `leads/serracap`,
        data: leadData
          ? {
              ...leadData,
              subAccountId: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID,
              eventAccessId: lead.eventAccessId,
            }
          : {
              ...lead,
              subAccountId: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID,
            },
      });
      if (response.leadId) {
        setLeadId(response.leadId);
        setLead((prevState) => ({
          ...prevState,
          protocol: response.protocol,
          protocolDate: response.protocolDate,
        }));
        return response.leadId;
      }
    },
    [lead]
  );

  const handleLocation = useCallback(async (queryParams) => {
    const location = await getLocation();
    const leadIdRequest = await sendLead();
    const eventAccessId = await updateEvent({
      type: "ACESSO",
      params: queryParams,
      location: location,
      leadId: leadIdRequest,
    });

    setLead((prevState) => ({ ...prevState, eventAccessId }));
  }, []);

  const updateLead = useCallback(
    async (leadData?: SingleLead) => {
      if (leadData?.cpf) {
        leadData.cpf = leadData.cpf.replace(/\D/g, "");
      }
      if (leadData?.cellphone) {
        leadData.cellphone = leadData.cellphone.replace(/\D/g, "");
      }
      if (leadData?.address_cep) {
        leadData.address_cep = leadData.address_cep.replace(/\D/g, "");
      }

      if (leadData?.seller_code) {
        leadData.seller_code = leadData.seller_code.replace(/\D/g, "");
      }

      if (leadData?.birthday && leadData.birthday.split("/").length > 1) {
        leadData.birthday = format(
          parse(leadData.birthday, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }

      if (lead?.cpf) {
        lead.cpf = lead.cpf.replace(/\D/g, "");
      }
      if (lead?.cellphone) {
        lead.cellphone = lead.cellphone.replace(/\D/g, "");
      }
      if (lead?.address_cep) {
        lead.address_cep = lead.address_cep.replace(/\D/g, "");
      }
      if (lead?.birthday && lead.birthday.split("/").length > 1) {
        lead.birthday = format(
          parse(lead.birthday, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        );
      }

      for (const label in lead) {
        console.log("obj", `${label} - value ${[label]}`);
      }
      const res = await request({
        showSuccessMessage: false,
        showLoading: true,
        timeout: 240000,
        method: "PUT",
        path: `leads/${leadId}`,
        data: leadData
          ? {
              ...leadData,
              subAccountId: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID,
            }
          : {
              ...lead,
              subAccountId: process.env.REACT_APP_SERRACAP_SUB_ACCOUNT_ID,
            },
      });
      setLead((prevState) => ({ ...prevState, protocol: res.leadId.protocol }));
    },

    [lead, leadId]
  );

  const subscribeLead = useCallback(
    async (leadData?: SingleLead) => {
      await request({
        showSuccessMessage: false,
        showLoading: true,
        timeout: 240000,
        method: "PUT",
        path: `leads/serracap/subscribed/${leadId}`,
      });
      setLeadId("");
    },

    [leadId]
  );
  useEffect(() => {
    if (leadId !== "" && localStorage.getItem("BRIA_ID")) {
      localStorage.setItem("BRIA_ID", leadId);
    }
  }, [leadId]);

  const stateClean = useCallback(async () => {
    setLead({});
  }, []);

  return (
    <LeadsContext.Provider
      value={{
        addLead,
        stateClean,
        lead,
        leadId,
        addDependent,
        sendLead,
        updateLead,
        getProfessions,
        subscribeLead,
        getLead,
        dependent,
        handleChange,
        confirm,
        updateDependents,
        handleLocation,
        updateEvent,
        paymentData,
        handleChangePayment,
        paymentMethod,
        handleChangePaymentMethod,
        setLoading,
        loading
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};
export const useLeads = () => {
  const context = useContext(LeadsContext);
  return context;
};
