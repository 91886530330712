import styled, { css } from "styled-components";
import { ISinglePageOption } from "../../../hooks/stylesController/stylesController.interface";

export const FooterContent = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  & > span {
    display: none;
  }
  & > div {
    width: 1018px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid #ccc;
    padding-bottom: 25px;
    display: flex;

    img,
    span {
      margin: 0;
    }
  }

  ${(props: { footer?: Omit<ISinglePageOption, "_id"> }) =>
    props.footer &&
    css`
      background-color: ${props.footer.backgroudColorMain} !important;
      color: ${props.footer.textColorMain} !important;
      & > div {
        border-bottom: 2px solid ${props.footer.textColorMain} !important;
      }
    `}

  @media (max-width: 768px) {
    width: 100%;
    & > span {
      display: inline;
      margin-top: 25px;
    }
    & > div {
      width: 90% !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      padding-bottom: 50px;
      & > span {
        display: none;
      }
    }
  }
`;
