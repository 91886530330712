import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import arrowLeft from "../../images/arrow_left.svg";
import BemMaisLogoColored from "../../images/bem_mais_logo_min_colored.png";
import BemMaisLogoWhite from "../../images/bem_mais_logo_min_white.png";
import BuildingIconBlue from "../../images/building_blue.svg";
import CalendaryIconBlue from "../../images/calendary_blue.svg";
import CircleCashIconBlue from "../../images/circle_cash_blue.svg";
import EmailIconBlue from "../../images/email_blue.svg";
import HouseIconBlue from "../../images/house_blue.svg";
import JobIconBlue from "../../images/job_blue.svg";
import LocationIconBlue from "../../images/location_blue.svg";
import MapIconBlue from "../../images/map_blue.svg";
import PersonalDocumentIconBlue from "../../images/personal_document_blue.svg";
import PersonIconBlue from "../../images/person_blue.svg";
import TelephoneIconBlue from "../../images/telephone_blue.svg";
import { useLeads } from "../leads/leadsHook";
import {
  IClient,
  InputTypes,
  ISinglePageOption,
  JorneyTypes,
  Pages,
  PageTypes,
  PlansTypes,
} from "./stylesController.interface";

type StylesControllerProviderProps = {
  children: ReactNode;
};

type IStylesControllerContextData = {
  client: IClient;
  fluxo: string;
  handleFluxo: (type: string) => void;
  currentPage: ISinglePageOption;
  lastPage: (history: any) => void;
  nextPage: (history: any) => Promise<void>;
};

const StylesControllerContext = createContext<IStylesControllerContextData>(
  {} as IStylesControllerContextData
);

export const StylesControllerProvider = ({
  children,
}: StylesControllerProviderProps) => {
  const [fluxo, setFluxo] = useState("sejabemmais");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [client, setClient] = useState<IClient>({
    jorneyType: JorneyTypes.B2C,
    plans: [
      {
        name: "Plano Individual",
        type: PlansTypes.SINGLE_PLAN,
        info: [
          "Telemedicina 24h/7 dias por semana",
          "Sorteios mensais no valor de R$ 10.000,00",
          "Seguro de acidentes pessoais por morte R$ 10.000,00",
          "Seguro funeral para titular da apólice",
        ],
        price: "R$ 19,90",
        services: [
          {
            title: "1 mês",
            type: "1 mês",
            description: "Mensal",
            icon: "string",
            price: "R$ 19,90",
          },
          {
            title: "6 meses",
            type: "6 meses",
            description: "6x  no cartão",
            icon: "string",
            price: "R$ 35,80",
          },
          {
            title: "Anual",
            type: "12 meses",
            description: "12x no cartão",
            icon: "string",
            price: "R$ 31,80",
          },
        ],
      },
      {
        name: "Plano Família",
        description: "(titular + 4 dependentes)",
        type: PlansTypes.MULTI_PLAN,
        price: "R$ 49,90",
        info: [
          "Telemedicina 24h/7 dias por semana",
          "Sorteios mensais no valor de R$ 10.000,00",
          "Seguro de acidentes pessoais por morte R$ 10.000,00",
          "Seguro funeral para titular da apólice",
        ],

        services: [
          {
            title: "1 mês",
            type: "1 mês",
            description: "Mensal",
            icon: "string",
            price: "R$ 39,80",
          },
          {
            title: "6 meses",
            type: "6 meses",
            description: "6x  no cartão",
            icon: "string",
            price: "R$ 35,80",
          },
          {
            title: "Anual",
            type: "12 meses",
            description: "12x no cartão",
            icon: "string",
            price: "R$ 31,80",
          },
        ],
      },
    ],
    pageOptions: {
      masterPage: {
        type: PageTypes.DEFAULT,
        backgroudColorMain: "#5393CB",
        textColorMain: "#fff",
        backIcon: arrowLeft,
        section: [],
      },
      footerColored: {
        type: PageTypes.DEFAULT,
        backgroudColorMain: "#5393CB",
        textColorMain: "#fff",
        img: BemMaisLogoWhite,
        section: [],
      },
      footerRender: {
        type: PageTypes.DEFAULT,
        textColorMain: "#2C6598",
        img: BemMaisLogoColored,
        section: [],
      },
      footerRenderWhite: {
        type: PageTypes.DEFAULT,
        section: [],
        textColorMain: "#fff",
        img: BemMaisLogoWhite,
      },
      pages: [
        {
          _id: "1",
          page: Pages.HOME,
          type: PageTypes.BEM_MAIS,
          label: "Planos",
          section: [],
          backgroudColorMain: "string",
          textColorMain: "string",
          paymentProvider: [],
        },
        {
          _id: "2",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          label: "Meus dados",
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.SELLER,
                  label: "seller_code",
                  placeholder: "Código Vendedor",
                  icon: PersonalDocumentIconBlue,
                  fluxo: "confirmesuacompra",
                },
                {
                  type: InputTypes.TEXT,
                  label: "name",
                  placeholder: "Nome Completo",
                  icon: PersonIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cpf",
                  placeholder: "CPF",
                  icon: PersonalDocumentIconBlue,
                },
                {
                  type: InputTypes.CIVIL_STATE,
                  label: "civil_state",
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_cep",
                  placeholder: "CEP",
                  icon: MapIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_street",
                  placeholder: "Endereço",
                  icon: HouseIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_number",
                  placeholder: "Número",
                  icon: LocationIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_complement",
                  placeholder: "Complemento",
                  icon: BuildingIconBlue,
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "3",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "email",
                  placeholder: "E-mail",
                  icon: EmailIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cellphone",
                  placeholder: "(00) 0 0000-0000",
                  icon: TelephoneIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "birthday",
                  placeholder: "Nascimento",
                  icon: CalendaryIconBlue,
                },
                {
                  type: InputTypes.GENRE_SELECTOR,
                  label: "genre",
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "4",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          conditionalProduct: "Plano Família",
          section: [
            {
              textField: {
                primaryText: "Chegamos na reta final!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.DEPENDENT,
                  label: "dependent",
                  quantity: 3,
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "5",
          page: Pages.PAGAMENTO,
          type: PageTypes.DEFAULT,
          label: "Pagamentos",
          section: [
            {
              textField: {
                primaryText: "Chegamos na reta final!",
                secundaryText:
                  "Vamos revisar e garantir que está tudo certo com a sua compra?",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "cpf",
                  icon: PersonalDocumentIconBlue,
                  placeholder: "CPF",
                },
              ],
            },
            {
              textField: {
                primaryText: "Chegamos na reta final!",
                secundaryText:
                  "Vamos revisar e garantir que está tudo certo com a sua compra?",
              },
            },
            {
              textField: {
                primaryText: "Informações de contato:",
              },
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "6",
          page: Pages.AGRADECIMENTO,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText:
                  " A sua compra foi realizada com sucesso e agora você faz parte do Bem Mais Clube de Benefícios.",
              },
            },
            {
              textField: {
                primaryText: "Aqui está o seu protocolo:",
                secundaryText:
                  "Em alguns instantes, você receberá todas as informações da ativação do seu Bem Mais Clube de Benefícios e a sua carteirinha digital em seu e-mail. Fique atento e lembre-se de checar o spam e a aba promocional, ok?",
              },
            },
            {
              textField: {
                secundaryText:
                  "Entre em contato com o nosso time de atendimento:",
                primaryText: "Ficou com alguma dúvida?",
              },
            },
            {
              textField: {
                secundaryText: "Site",
                primaryText: "www.bemmaisclubedebeneficios.com.br",
                href: "http://www.bemmaisclubedebeneficios.com.br",
              },
            },

            {
              textField: {
                secundaryText: "Telefone",
                primaryText: "0800 942 3040",
                href: "0800 942 3040",
              },
            },
            {
              textField: {
                primaryText:
                  "Bem Mais Clube de Benefícios Você merece muito mais!",
              },
            },
            {
              textField: {
                secundaryText: "Perguntas frequentes",
                primaryText: "Clique aqui para acessar nosso FAQ",
                href: "https://bria-assets.s3.sa-east-1.amazonaws.com/faq.pdf",
              },
            },
          ],
          backgroudColorMain: "#9516B9",
          textColorMain: "#FFF",
        },
        {
          _id: "7",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "name",
                  placeholder: "Nome Completo",
                  icon: PersonIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "email",
                  placeholder: "E-mail",
                  icon: EmailIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cellphone",
                  placeholder: "(00) 0 0000-0000",
                  icon: TelephoneIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cpf",
                  placeholder: "CPF",
                  icon: PersonalDocumentIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "birthday",
                  placeholder: "Nascimento",
                  icon: CalendaryIconBlue,
                },
                {
                  type: InputTypes.GENRE_SELECTOR,
                  label: "genre",
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "8",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "address_cep",
                  placeholder: "CEP",
                  icon: MapIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_street",
                  placeholder: "Endereço",
                  icon: HouseIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_number",
                  placeholder: "Número",
                  icon: LocationIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_complement",
                  placeholder: "Complemento",
                  icon: BuildingIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "seller_code",
                  placeholder: "Código Vendedor",
                  icon: PersonalDocumentIconBlue,
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "9",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "name",
                  placeholder: "Nome Completo",
                  icon: PersonIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "email",
                  placeholder: "E-mail",
                  icon: EmailIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cellphone",
                  placeholder: "(00) 0 0000-0000",
                  icon: TelephoneIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "cpf",
                  placeholder: "CPF",
                  icon: PersonalDocumentIconBlue,
                },
                {
                  type: InputTypes.GENRE_SELECTOR,
                  label: "genre",
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
        {
          _id: "10",
          page: Pages.MEUS_DADOS,
          type: PageTypes.DEFAULT,
          section: [
            {
              textField: {
                primaryText: "Falta Pouco!",
                secundaryText:
                  "Precisamos de mais alguns dados para prosseguir.",
                tertiaryText:
                  "*Preencha certinho para que não tenha problemas para usar o seu Clube de Benefícios no futuro",
              },
            },
            {
              form: [
                {
                  type: InputTypes.TEXT,
                  label: "address_cep",
                  placeholder: "CEP",
                  icon: MapIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_street",
                  placeholder: "Endereço",
                  icon: HouseIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_number",
                  placeholder: "Número",
                  icon: LocationIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "address_complement",
                  placeholder: "Complemento",
                  icon: BuildingIconBlue,
                },
                {
                  type: InputTypes.TEXT,
                  label: "birthday",
                  placeholder: "Nascimento",
                  icon: CalendaryIconBlue,
                },
              ],
            },
          ],
          backgroudColorMain: "#FFFFFF",
          textColorMain: "#2C6598",
        },
      ],
    },
  });

  const pathname = window.location.pathname.split("/")[1];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lead, updateLead } = useLeads();

  const [currentPage, setCurrentPage] = useState<ISinglePageOption>(
    {} as ISinglePageOption
  );
  console.log("stylesController-currentPage", currentPage);
  const nextPage = useCallback(
    async (history: any) => {
      let nPageIndex = client.pageOptions.pages.findIndex(
        (p) => p._id === currentPage._id
      );
      if (nPageIndex === client.pageOptions.pages.length) {
        return;
      }
      let nPage = client.pageOptions.pages[nPageIndex + 1];

      if (nPage.conditionalProduct) {
        if (lead.selected_product !== nPage.conditionalProduct) {
          nPage = client.pageOptions.pages[nPageIndex + 2];
        }
      }

      if (!nPage) {
        return;
      }

      if (nPage.page === Pages.HOME) {
        setCurrentPage(nPage);
        if (pathname === "") {
          return;
        }
        await history.push("/");
      }
      if (nPage.page === Pages.PLANOS) {
        setCurrentPage(nPage);
        if (pathname === "planos") {
          return;
        }

        await history.push("/planos");
      }

      if (nPage.page === Pages.MEUS_DADOS) {
        if (lead.cpf) {
          await updateLead({ ...lead, mql: true });
        }
        setCurrentPage(nPage);

        if (pathname === "meus-dados") {
          return;
        }
        await history.push("/meus-dados");
      }
      if (nPage.page === Pages.PAGAMENTO) {
        await updateLead({ ...lead, sql: true });
        setCurrentPage(nPage);

        if (pathname === "pagamento") {
          return;
        }
        await history.push("/pagamento");
      }

      if (nPage.page === Pages.AGRADECIMENTO) {
        await updateLead({ ...lead, sql_payment: true });
        setCurrentPage(nPage);
        if (pathname === "agradecimento") {
          return;
        }
        await history.push("/agradecimento");
      }
    },
    [pathname, updateLead, client, currentPage]
  );

  const lastPage = useCallback(
    (history: any) => {
      let lastPageIndex = client.pageOptions.pages.findIndex(
        (p) => p._id === currentPage._id
      );
      let backPage = client.pageOptions.pages[lastPageIndex - 1];

      if (backPage.conditionalProduct) {
        if (lead.selected_product !== backPage.conditionalProduct) {
          backPage = client.pageOptions.pages[lastPageIndex - 2];
        }
      }

      if (!backPage) {
        return;
      }

      if (backPage.page === Pages.PLANOS) {
        setCurrentPage(backPage);
        if (pathname === "planos") {
          return;
        }
        history.push("/planos");
      }
      if (backPage.page === Pages.MEUS_DADOS) {
        setCurrentPage(backPage);
        if (pathname === "meus-dados") {
          return;
        }
        history.push("/meus-dados");
      }
      if (backPage.page === Pages.PAGAMENTO) {
        setCurrentPage(backPage);
        if (pathname === "pagamento") {
          return;
        }
        history.push("/pagamento");
      }
      if (backPage.page === Pages.AGRADECIMENTO) {
        setCurrentPage(backPage);
        if (pathname === "agradecimento") {
          return;
        }
        history.push("/agradecimento");
      }
    },
    [pathname, client, currentPage]
  );

  useEffect(() => {
    if (
      (pathname === "" ||
        pathname === "confirmesuacompra" ||
        pathname === "vendedor") &&
      (!currentPage || currentPage.page !== Pages.HOME)
    ) {
      let page = client.pageOptions.pages.filter(
        (p) => p.page === Pages.HOME
      )[0];
      page && setCurrentPage(page);
    }
    if (
      pathname === "planos" &&
      (!currentPage || currentPage.page !== Pages.PLANOS)
    ) {
      let page = client.pageOptions.pages.filter(
        (p) => p.page === Pages.PLANOS
      )[0];
      page && setCurrentPage(page);
    }

    if (
      pathname === "meus-dados" &&
      (!currentPage || currentPage.page !== Pages.MEUS_DADOS)
    ) {
      let page = client.pageOptions.pages.filter(
        (p) => p.page === Pages.MEUS_DADOS
      )[0];
      page && setCurrentPage(page);
    }
    if (
      pathname === "pagamento" &&
      (!currentPage || currentPage.page !== Pages.PAGAMENTO)
    ) {
      let page = client.pageOptions.pages.filter(
        (p) => p.page === Pages.PAGAMENTO
      )[0];
      page && setCurrentPage(page);
    }
    if (
      pathname === "agradecimento" &&
      (!currentPage || currentPage.page !== Pages.AGRADECIMENTO)
    ) {
      let page = client.pageOptions.pages.filter(
        (p) => p.page === Pages.AGRADECIMENTO
      )[0];
      page && setCurrentPage(page);
    }
  }, [pathname, currentPage, client]);

  const handleFluxo = useCallback(
    (type: string) => {
      setFluxo(type);
      console.log(fluxo);
    },
    [setFluxo]
  );

  return (
    <StylesControllerContext.Provider
      value={{
        client,
        fluxo,
        handleFluxo,
        currentPage,
        lastPage,
        nextPage,
      }}
    >
      {children}
    </StylesControllerContext.Provider>
  );
};

export const useStylesController = () => {
  const context = useContext(StylesControllerContext);
  return context;
};
