import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DefaultMasterPage } from "../../components/defaultMasterPage/defaultMasterPage";
import { useLeads } from "../../hooks/leads/leadsHook";
import { PageTypes } from "../../hooks/stylesController/stylesController.interface";
import { useStylesController } from "../../hooks/stylesController/stylesControllerHook";
import { MyDataDefault } from "../myData/myDataTypes/default/default";

export const MyData = () => {
  const { currentPage } = useStylesController();
  const { addLead, sendLead } = useLeads();
  const params: any = useParams();
  const history: any = useHistory();
  useEffect(() => {
    console.log(params);
    if (params.product === "plano-individual") {
      addLead({
        selected_product: "Plano Individual",
        product_price: "19,90",
        product_value: "19,90",
      });
      sendLead({
        selected_product: "Plano Individual",
        product_price: "19,90",
        product_value: "19,90",
      });
    } else if (params.product === "plano-familia") {
      addLead({
        selected_product: "Plano Família",
        product_price: "49,90",
        product_value: "49,90",
      });
      sendLead({
        selected_product: "Plano Família",
        product_price: "49,90",
        product_value: "49,90",
      });
    } else if (params.product) {
      history.push("/");
    }
  }, []);

  if (currentPage.type === PageTypes.BEM_MAIS) {
    return <h1>myData1</h1>;
  } else {
    return (
      <DefaultMasterPage>
        <MyDataDefault />
      </DefaultMasterPage>
    );
  }
};
