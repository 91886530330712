import styled, { css } from "styled-components";

export const GenreSelector = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const GenreBox = styled.div`
  display: flex;
  align-items: center;
  //width: 200px;
  height: 35px;
  opacity: 0.5;
  margin-top: 8px;
  margin-left: 8px;
  cursor: pointer;

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      opacity: 1 !important;
      & > div {
        background: #2c6598;
      }
      & > span {
        font-weight: bold;
      }
    `}

  & > div {
    border: 2px solid #2c6598;
    border-radius: 19px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      object-fit: contain;
    }
  }
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    //line-height: 105.18%;
    text-align: center;
    color: #2c6598;
    margin-left: 4px;
  }
`;
